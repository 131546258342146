import React from "react";
import ReactPlayer from "react-player/lazy";
import { useDownloadURL } from "react-firebase-hooks/storage";
import Loading from "components/Loading";
import { storage } from "lib/firebase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const AudioPlayer = ({ doc }) => {
  const [url, loading] = useDownloadURL(
    storage.ref(`/user-content/${doc.user_id}/audio/${doc.content.audio.audio}`)
  );

  if (loading) return <Loading />;

  return (
      <Card>
          <CardContent>
              <div className="player-audio-wrapper">
                  <ReactPlayer
                      controls
                      className="react-player"
                      width="100%"
                      height={55}
                      url={url}
                      config={{
                          file: {
                              forceAudio: true
                          }
                      }}
                  />
              </div>
          </CardContent>
      </Card>
  );
};

export default AudioPlayer;
