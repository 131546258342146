import React, { useMemo } from "react";
import ReactPlayer from "react-player/lazy";
import { makeStyles } from '@material-ui/core/styles';

const userStyled = makeStyles({
   root: {
       '& video': {

       }
   }
});

const VideoPlayer = ({ fileUrl, file }) => {
    const classes = userStyled();

  const url = useMemo(
    () =>
      file
        ? URL.createObjectURL(file)
        : fileUrl,
    []
  );

  return (
      <div className={file && classes.root}>
          <ReactPlayer
              controls
              playing
              className="react-player"
              width="100%"
              height="100%"
              url={url}
              config={{
                  file: {
                      forceVideo: true,
                  },
              }}
          />
      </div>
  );
};

export default VideoPlayer;
