import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

const useStyles = makeStyles((theme) => ({
  menu: {
    width: 150,
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: 30,
    },
  },
}))(MenuItem);

const ItemContextMenu = ({ className, onSelect, item }) => {
  const classes = useStyles();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "contextMenu",
  });

  const handleClick = (action) => () => {
    popupState.close();
    onSelect(action, item);
  };

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        className={className}
        {...bindTrigger(popupState)}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        disableAutoFocusItem
        getContentAnchorEl={null}
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.menu,
        }}
      >
        <StyledMenuItem onClick={handleClick("edit")}>
          <ListItemIcon color="primary">
            <EditIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClick("share")}>
          <ListItemIcon>
            <ShareIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="Share"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={handleClick("delete")}>
          <ListItemIcon>
            <DeleteIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default ItemContextMenu;
