import React from "react";
import ShareIcon from "@material-ui/icons/Share";
import { makeStyles } from "@material-ui/core/styles";
import { getFolderColor } from "constants/colors";
import FolderMask from "assets/folder_mask.svg";

const useStyles = makeStyles((theme) => ({
  root: props =>  ({
      position: 'relative',
      width: '100%',
      minHeight: 26,
      "&::after": {
          content: '""',
          position: "absolute",
          bottom: props.size === 'sm' ? 5 : 6,
          right: props.size === 'sm' ? 1 : 2,
          width: props.size === 'sm' ? 20 : 90,
          height: props.size === 'sm' ? 20 : 70,
          borderRadius: props.size === 'sm' ? 0 : 4,
          background:
              "linear-gradient(135deg, rgba(255, 255, 255, 0)30%, rgba(0, 0, 0, 0.1)100%)",
      },
  }),
  shareIcon: props => ({
    position: "absolute",
    bottom: props.size === 'sm' ? 9 : 10,
    left: props.size === 'sm' ? 10 : 72,
    fontSize: props.size === 'sm' ? 12 : 20,
    color: "#fff",
  }),
}));

const CustomFolderIcon = ({ style, src }) => {
  return <img style={style} src={src} />;
};

const FolderIcon = ({ color, image, isShared, size }) => {
  const classes = useStyles({ size });
  const maskStyle =
    size === "sm"
      ? {
          height: 24,
          width: 30,
        }
      : {
          height: 80,
          width: "100%",
        };

  return (
    <span className={classes.root}>
      {image ? (
        <CustomFolderIcon
          src={image}
          style={{
            color: getFolderColor(color),
            WebkitMaskBoxImage: `url(${FolderMask})`,
            'mask-image': `url(${FolderMask})`,
            'mask-size': 'contain',
            'mask-repeat': 'no-repeat',
            ...maskStyle,
          }}
        />
      ) : (
        <svg
          width={size === "sm" ? "30px" : "100px"}
          height={size === "sm" ? "24px" : "80px"}
          viewBox="0 0 105 87"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
              transform="translate(-448.000000, -97.000000)"
              fill={getFolderColor(color)}
            >
              <g id="Tint" transform="translate(448.000000, 97.000000)">
                <path
                  d="M4.375,0 L31.3541667,0 C32.7312299,0 34.0279288,0.645361694 34.8541667,1.74193548 L40.9791667,6.87096774 C41.8054045,7.96754153 43.1021035,8.61290323 44.4791667,8.61290323 L100.625,8.61290323 C103.041246,8.61290323 105,10.562631 105,12.9677419 L105,82.6451613 C105,85.0502722 103.041246,87 100.625,87 L4.375,87 C1.95875429,87 0,85.0502722 0,82.6451613 L0,4.35483871 C0,1.94972777 1.95875429,0 4.375,0 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </svg>
      )}
      {isShared && <ShareIcon className={classes.shareIcon} />}
    </span>
  );
};

export default FolderIcon;
