import React from "react";
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import {
  bindMenu,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import Menu from '@material-ui/core/Menu';
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ReactComponent as FolderIcon } from "assets/icons/add_folder.svg";
import { ReactComponent as AudioIcon } from "assets/badges/audio.svg";
import { ReactComponent as DocumentIcon } from "assets/badges/document.svg";
import { ReactComponent as PhotoIcon } from "assets/badges/photo.svg";
import { ReactComponent as TextIcon } from "assets/badges/text.svg";
import { ReactComponent as VideoIcon } from "assets/badges/video.svg";
import NoteTypes from 'constants/noteTypes';

import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  menu: {
    width: 150,
  },
  hover: {
    backgroundColor: theme.palette.primary.light,
  }
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: 30,
    },
  },
}))(MenuItem);

const NewItemMenu = ({ folderId, onAddFolder, children, history }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "newItem",
  });

  const classes = useStyles();

  const handleClick = (action) => () => {
    popupState.close();
    if (action === 'folder') {
      onAddFolder();
    } else {
      const path = folderId ? `/folders/${folderId}/new/${action}` : `/folders/new/${action}`;
      history.push(path);
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        ...bindToggle(popupState),
        className: cx(children.props.className, { [classes.hover]: popupState.isOpen})
      })}

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{
          paper: classes.menu,
        }}
      >
        <StyledMenuItem onClick={handleClick("folder")}>
          <ListItemIcon>
            <FolderIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="New Folder"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={handleClick(NoteTypes.Text)}>
          <ListItemIcon>
            <TextIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="Text"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClick(NoteTypes.Document)}>
          <ListItemIcon>
            <DocumentIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="Document"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClick(NoteTypes.Photo)}>
          <ListItemIcon>
            <PhotoIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="Image"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClick(NoteTypes.Audio)}>
          <ListItemIcon>
            <AudioIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="Audio"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClick(NoteTypes.Video)}>
          <ListItemIcon>
            <VideoIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary="Video"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default withRouter(NewItemMenu);
