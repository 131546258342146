import dayjs from "dayjs";
import React, {useEffect} from "react";
import { useDrag } from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";
import ListItem from "@material-ui/core/ListItem";
import {Link} from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import FileIcon from "../FileIcon";
import ICONS from "../../constants/noteIcons";
import ItemContextMenu from "../ItemContextMenu";
import {fade, makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    listIcon: {
        minWidth: theme.spacing(4),
    },
    icon: {
        position: 'absolute',
        width: 16,
        bottom: 2,
        right: 2
    },
    listItem: {
        '& .MuiListItem-button:hover': {
            backgroundColor: theme.palette.primary.light
        },
        '& .contextMenu': {
            display: 'none',
        },
        '&:hover': {
            '& .contextMenu': {
                display: 'block'
            },
            '& .timestamp': {
                display: 'none',
            }
        }
    },
    iconContainer: {
        width: 32,
        height: 26,
        position: 'relative',
        marginRight: theme.spacing(2),
        borderRadius: 3,
        border: '1px solid',
        borderColor: fade(theme.palette.text.primary, .2),
    }
}));

const formatDate = (date) => {
    let d;
    if(date.toDate) {
        d = date.toDate();
    } else if (date._seconds) {
        d = new Date(date._seconds * 1000);
    }
    return dayjs(d).format("MMMM D, YYYY");
}

const FileItem = ({ item, selectionMode, onSelect, selected }) => {
    const classes = useStyles();
    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: "FILE",
            item: { id: item.id },
            canDrag: () => !!selectionMode && selected,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
        }),
        [selectionMode, selected]
    );

    useEffect(() => {
        dragPreview(getEmptyImage(), { captureDraggingState: true })
    }, []);

    const onClick = () => {
        if (selectionMode) {
            onSelect(item);
        }
    };

    return (
        <ListItem
            divider
            button
            selected={selected}
            component={!selectionMode && Link}
            onClick={onClick}
            to={`/notes/${item.id}`}
            classes={{
                container: classes.listItem
            }}
            ref={drag}
        >
            {selectionMode && (
                <ListItemIcon className={classes.listIcon}>
                    {selectionMode && (
                        <Checkbox color="primary" edge="start" checked={selected} disableRipple />
                    )}
                </ListItemIcon>
            )}
            <ListItemText
                disableTypography
                primary={
                    <Box display="flex" alignItems="center">
                        <div className={classes.iconContainer}>
                            <FileIcon doc={item} size="sm" />
                            <img
                                alt={item.name}
                                src={ICONS[item.note_type]}
                                className={classes.icon}
                            />
                        </div>
                        <Typography variant="body1">{item.name}</Typography>
                    </Box>
                }
            />
            <ListItemSecondaryAction>
                <ItemContextMenu className="contextMenu" item={item} onSelect={onSelect} />
                <Typography variant="body2" color="textSecondary" className="timestamp">
                    {formatDate(item.date_created)}
                </Typography>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default FileItem;