import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import cookie from "react-cookies";
import {
  useCollectionData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Comments from "../note/components/Comments";
import Loading from "components/Loading";
import NotFound from "components/NotFound";
import { firestore } from "lib/firebase";
import Document from "./components/Document";
import Video from "containers/note/components/VideoNote";
import Audio from "containers/note/components/AudioNote";
import PDFNote from "containers/note/components/PDFNote";
import NamePrompt from "./components/NamePrompt";
import UserContext from "context/user";
import NoteTypes from "constants/noteTypes";
import useComments from "hooks/useComments";
import ALLOWED_ANNOTATIONS from "constants/allowedAnnotations";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 600,
  },
  spacer: {
    display: "block",
    marginBottom: theme.spacing(2),
  },
  desc: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  heading: {
    fontWeight: theme.typography.fontWeightMedium,
    flex: 1,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  expanded: {
    margin: 0,
  },
  fullscreen: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.9)",
    zIndex: 1101,
    overflowY: "auto",
    "& .img-actions": {
      display: "none",
    },
    "& .loadable-image": {},
  },
  modalHeader: {
    textAlign: "right",
    color: "white",
  },
  closeBtn: {
    color: "white",
  },
}));

const FileViewer = ({ match }) => {
  const { user, setUser } = useContext(UserContext);
  const classes = useStyles();
  const [commenter, setCommenter] = useState({});
  const [showNamePrompt, setShowNamePrompt] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState("all");
  const [fullScreen, setFullScreen] = useState(false);
  const commenterCallback = useRef();
  const [doc, loading, error] = useDocumentDataOnce(
    firestore.collection("Notes").doc(match.params.documentId),
    { idField: "id" }
  );
  const {
    loading: loadingComments,
    annotations,
    thread,
    addComment,
  } = useComments(match.params.documentId);

  useEffect(() => {
    const name = cookie.load("commenter_name");
    if (name) {
      setCommenter({
        name,
      });
    }
  }, []);

  useEffect(() => {
    if (doc) {
      firestore
        .collection("Users")
        .doc(doc.user_id)
        .get()
        .then((s) => {
          const u = s.data();
          u.id = s.id;
          setUser(u);
        });
    }
  }, [doc]);

  const onNameSaved = (name) => {
    setCommenter({ name });
    setShowNamePrompt(false);
    if (commenterCallback.current) {
      commenterCallback.current(name);
    }
  };

  const toggleAnnotations = (e) => {
    e.stopPropagation();
    setShowAnnotations(showAnnotations === "all" ? "none" : "all");
  };

  const getCommenterName = () => {
    return new Promise((resolve) => {
      if (!commenter.name) {
        setShowNamePrompt(true);
        commenterCallback.current = resolve;
      } else {
        resolve(commenter.name);
      }
    });
  };

  const onFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  if (loading) return <Loading />;
  else if (error) {
    return <NotFound />;
  }

  const renderNoteView = () => {
    if (doc.note_type === NoteTypes.Video) {
      return <Video doc={doc} />;
    } else if (doc.note_type === NoteTypes.Audio) {
      return <Audio doc={doc} />;
    } else if (doc.note_type === NoteTypes.Text) {
      return (
        <Box
          padding={2}
          dangerouslySetInnerHTML={{ __html: doc.content.text.html_text }}
        />
      );
    } else if (
      doc.note_type === NoteTypes.Document &&
      doc.content.file_type === "pdf"
    ) {
      return <PDFNote doc={doc} />;
    }

    const content = (
      <Document
        getCommenterName={getCommenterName}
        doc={doc}
        annotations={annotations}
        showAnnotations={showAnnotations}
        onFullScreen={onFullScreen}
      />
    );

    return (
      <div className={fullScreen && classes.fullscreen}>
        {fullScreen && (
          <div className={classes.modalHeader}>
            <IconButton onClick={onFullScreen} className={classes.closeBtn}>
              <CloseIcon color="inherit" />
            </IconButton>
          </div>
        )}
        {content}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Helmet title={`Notes from ${user.name}`}>
        <meta property="og:title" content={`Notes from ${user.name}`} />
        <meta
          property="description"
          content={`${user.name} is sharing notes from the THINKERS Notebook. Click the link to view and comment.`}
        />
        <meta
          property="og:description"
          content={`${user.name} is sharing notes from the THINKERS Notebook. Click the link to view and comment.`}
        />
      </Helmet>
      <Container>
        <Typography className={classes.title} variant="h5" color="textPrimary">
          {doc.name}
        </Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          Last changed:{" "}
          {dayjs(doc.date_last_modified.toDate()).format("MM/DD/YYYY h:mm A")}
        </Typography>
        {/*{doc.location && (*/}
        {/*  <Typography*/}
        {/*    component="a"*/}
        {/*    variant="body2"*/}
        {/*    target="_blank"*/}
        {/*    href={`https://www.google.com/maps/search/?api=1&query=${doc.location.latitude},${doc.location.longitude}`}*/}
        {/*  >*/}
        {/*    {doc.location.name}*/}
        {/*  </Typography>*/}
        {/*)}*/}
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.desc}
        >
          {doc.description}
        </Typography>
        <Card>{renderNoteView()}</Card>
        <br />
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <Box display="flex" justifyContent="space-between" flex={1}>
              <Typography variant="h6" className={classes.heading}>
                {thread.length} Comments
              </Typography>
              {ALLOWED_ANNOTATIONS.includes(doc.note_type) && (
                <>
                  {showAnnotations === "all" ? (
                    <Button
                      onClick={toggleAnnotations}
                      color="primary"
                      startIcon={<VisibilityOffIcon />}
                    >
                      Hide Annotations
                    </Button>
                  ) : (
                    <Button
                      onClick={toggleAnnotations}
                      color="primary"
                      startIcon={<VisibilityIcon />}
                    >
                      Show Annotations
                    </Button>
                  )}
                </>
              )}
            </Box>
          </ExpansionPanelSummary>
          <div>
            <Divider />
            <Box padding={2}>
              <Comments
                doc={doc}
                comments={thread}
                loading={loadingComments}
                getCommenterName={getCommenterName}
                addComment={addComment}
              />
            </Box>
          </div>
        </ExpansionPanel>
        <ExpansionPanel
          classes={{
            expanded: classes.expanded,
          }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel2a-header"
          >
            <Typography variant="h6" className={classes.heading}>
              Tags
            </Typography>
          </ExpansionPanelSummary>
          <div>
            <Divider />
            {doc.tags.length ? (
              <Box padding={2}>
                {doc.tags.map((i) => (
                  <Chip
                    key={i}
                    label={i}
                    color="primary"
                    className={classes.chip}
                  />
                ))}
              </Box>
            ) : null}
            {doc.system_tags ? (
              <Box padding={2}>
                {doc.system_tags.map((i) => (
                  <Chip
                    key={i}
                    label={i}
                    color="default"
                    className={classes.chip}
                  />
                ))}
              </Box>
            ) : null}
          </div>
        </ExpansionPanel>
      </Container>
      {showNamePrompt && (
        <NamePrompt
          onSubmit={onNameSaved}
          onClose={() => {
            setShowNamePrompt(false);
            commenterCallback.current = null;
          }}
        />
      )}
    </div>
  );
};

export default FileViewer;
