import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { getEmptyImage } from 'react-dnd-html5-backend';
import ItemContextMenu from "../ItemContextMenu";
import cx from "classnames";
import FileIcon from "../FileIcon";
import CheckIcon from "@material-ui/icons/Check";
import ICONS from "../../constants/noteIcons";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    borderRadius: 6,
    transition: "all .2s ease",
    "& a": {
      display: "inline-block",
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      "& > button": {
        display: "block",
      },
    },
  },
  cover: {
    width: 100,
    height: 100,
    boxShadow: "0 0 1px 2px rgba(0,0,0,.1)",
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    overflow: "hidden",
    fontSize: 10,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  badge: {
    position: "absolute",
    bottom: 4,
    right: 4,
  },
  checkboxContainer: {
    position: "absolute",
    bottom: 4,
    left: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkbox: {
    color: theme.palette.background.paper,
    fontSize: 20,
  },
  name: {
    lineHeight: 1,
    marginBottom: 4,
  },
  selected: {
    border: "2px solid",
    borderColor: theme.palette.primary.dark,
  },
  menuIcon: {
    position: "absolute",
    right: 0,
    top: -4,
    display: "none",
  },
}));

const formatDate = (date) => {
  let d;
  if(date.toDate) {
    d = date.toDate();
  } else if (date._seconds) {
    d = new Date(date._seconds * 1000);
  }
  return dayjs(d).format("MMMM D, YYYY");
}


const FileItem = ({ file, selectionMode, onSelect, selected, onFileAction }) => {
  const classes = useStyles();
  const history = useHistory();
  const [collected, drag, dragPreview] = useDrag(
    () => ({
      type: "FILE",
      item: { id: file.id },
      canDrag: () => !!selectionMode && selected,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      }),
    }),
    [selectionMode, selected]
  );

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, []);

  const onClick = () => {
    if (selectionMode) {
      onSelect(file);
    } else {
      history.push(`/notes/${file.id}`);
    }
  };

  // if (collected.isDragging) return <div ref={dragPreview} />;

  return (
    <Box className={classes.card} ref={drag}>
      <ItemContextMenu className={classes.menuIcon} onSelect={onFileAction} item={file} />
      <div
        onClick={onClick}
        className={cx(classes.cover, {
          [classes.selected]: selected,
        })}
      >
        <FileIcon doc={file} />
        {selected && (
          <div className={classes.checkboxContainer}>
            <CheckIcon className={classes.checkbox} color="primary" />
          </div>
        )}
        <img
          alt={file.name}
          src={ICONS[file.note_type]}
          className={classes.badge}
        />
      </div>
      <Typography
        variant="subtitle2"
        color="textPrimary"
        className={classes.name}
      >
        {file.name}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {formatDate(file.date_created)}
      </Typography>
    </Box>
  );
};

export default FileItem;
