import React from 'react';
import uuid from "lib/uuid";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import StopIcon from "@material-ui/icons/Stop";
import MicIcon from "@material-ui/icons/Mic";
import Typography from "@material-ui/core/Typography";
import {useAudioRecorder} from "hooks/useAudioRecorder";
import { milisecondsToPretty } from 'utils';


const AudioRecorder = ({ setFile }) => {
    const { duration, toggleRecording, recording } = useAudioRecorder({
        handleFinishRecording: (blob) => {
            const newFile = new File([blob], `${uuid()}.mp3`, {
                type: 'audio/mp3',
            });
            setFile(newFile);
        },
        setFiles: () => false,
    });

    return (
        <Box paddingBottom={3}>
            <Box marginBottom={3}>
                <Fab color="secondary" onClick={toggleRecording}>
                    { recording ? <StopIcon /> : <MicIcon />}
                </Fab>
            </Box>
            <Typography>
                {recording ? milisecondsToPretty(duration) : 'Or start recording using your attached microphone.'}
            </Typography>
        </Box>
    )
};

export default AudioRecorder;