import React, {useContext, useState} from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "components/Button";
import ChipInput from 'components/ChipInput';
import {FieldValue, firestore} from "lib/firebase";
import SessionContext from "context/session";
import Tags from "containers/note-editor/components/TagsInput";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        justifyContent: 'flex-start'
    }
}))(MuiDialogActions);


export default function EditTags({ note, onSave, onClose }) {
    const [tags, setTags] = useState(note.tags || []);
    const { user } = useContext(SessionContext);

    const handleSave = async () => {
        await onSave({ tags });
        await updateUserTags();
        onClose();
    };

    const updateUserTags = async () => {
        await firestore
            .collection("Users")
            .doc(user.id)
            .update({
                tags: FieldValue.arrayUnion(...tags),
            });
    };

    return (
        <Dialog disableBackdropClick open onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle onClose={onClose}>Edit tags</DialogTitle>
            <DialogContent dividers>
                <Tags
                    options={user.tags || []}
                    tags={tags}
                    onChange={setTags}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSave} color="primary">
                    Save changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
