import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import { firestore } from "lib/firebase";
import "./style.css";

const Announcement = ({
  match: {
    params: { id }
  }
}) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    firestore
      .collection("Announcements")
      .doc(id)
      .get()
      .then(snap => {
        if (snap.exists) {
          setState({ loading: false, data: snap.data() });
        } else {
          setState({ ...state, loading: false });
        }
      });
  }, []);

  if (state.loading) {
    return <LinearProgress />;
  } else if (!state.data) {
    return <div>Invalid link.</div>;
  }
  return (
    <div className="announcement">
      <img
        alt="banner image"
        className="announcement__banner"
        src={state.data.image_url}
      />
      <div className="announcement__body">
        <h1>{state.data.title}</h1>
        <h5>{state.data.subtitle}</h5>
        <div className="date">
          {state.data.date_announced.toDate().toLocaleDateString()}
        </div>
        <div
          className="announcement-content"
          dangerouslySetInnerHTML={{ __html: state.data.body }}
        />
        {state.data.action_link && (
          <Button
            variant="contained"
            color="primary"
            as="a"
            href={state.data.action_link}
          >
            Open
          </Button>
        )}
      </div>
    </div>
  );
};

export default Announcement;
