import React, { useEffect, useState, useContext } from "react";
import {
  useCollectionData,
  useDocumentDataOnce
} from "react-firebase-hooks/firestore";
import dayjs from "dayjs";
import orderBy from 'lodash/orderBy';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ListIcon from "@material-ui/icons/List";
import SortIcon from "@material-ui/icons/Sort";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from "material-ui-popup-state/hooks";
import { firestore } from "lib/firebase";
import UserContext from 'context/user';
import Loading from "components/Loading";
import NotFound from 'components/NotFound';
import NotificationSettings from "./components/NotificationSettings";
import ListView from "./components/ListView";
import CardView from "./components/CardView";
import { getFolderColor } from '../../constants/colors';

const useStyles = makeStyles(theme => ({
  hero: {
    background: "linear-gradient(to bottom, rgba(207,231,231,0.32) 0%,rgba(255,255,255,1) 100%)",
    boxShadow: "0 0 2px 2px rgba(0,0,0,.2)",
    marginBottom: theme.spacing(3),
    "& .inner": {
      position: 'relative',
      minHeight: '30vh',
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      color: theme.palette.common.white,
      borderBottomLeftRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      overflow: 'hidden',
      '&:before': {
        content: '""',
        position: 'absolute',
        background: "linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.5) 100%)",
        top: 0,
        bottom: 0,
        left: 0,
        width: '100%'
      },
      [theme.breakpoints.up("md")]: {
        height: 400,
      }
    },
    "& h5": {
      fontWeight: 600
    }
  },
  main: {
    paddingBottom: theme.spacing(5),
  }
}));

const SortLabels = {
  name: 'Name',
  date_created: 'Created',
  date_last_modified: 'Modified',
  note_type: 'Note Type'
};

const FileBrowser = ({ history, match: { params: { folderId }} }) => {
  const classes = useStyles();
  const {
    setUser,
    user,
  } = useContext(UserContext);
  const popupState = usePopupState({ variant: "popover", popupId: "sortMenu" });
  const [showNotifModal, setShowNotifModal] = useState(false);
  const [view, setView] = useState("card");
  const [sortKey, setSortKey] = useState('name');
  const [folder, loadingFolder, folderLoadError] = useDocumentDataOnce(
    firestore.collection("Folders").doc(folderId),
    { idField: 'id' }
  );
  const [folders, loadingFolders, foldersLoadingError ] = useCollectionData(
    firestore.collection('Folders')
      .where('parent_id', '==', folderId)
      .where('is_shared', '==', true),
    { idField: 'id' }
  );
  const [docs, loadingDocs, docsLoadingError] = useCollectionData(
    firestore.collection('Notes')
      .where('folder_id', '==', folderId)
      .where('is_shared', '==', true),
    { idField: 'id' }
  );

  useEffect(() => {
    if (folder && !user.id) {
      firestore
        .collection("Users")
        .doc(folder.user_id)
        .get()
        .then(s => {
          const doc = s.data();
          doc.id = s.id;
          setUser(doc);
        });
    }
  }, [folder]);

  const changeView = type => () => {
    setView(type);
  };

  const onFolderSelect = (folder) => {
    history.push(`/f/${folder.id}`);
  }

  const onSort = (key) => () => {
    setSortKey(key);
    popupState.close();
  }

  if (loadingFolder) return <Loading linear={false} />;
  if (folderLoadError)
    return <NotFound />;

  const sortedDocs = orderBy(docs, sortKey, sortKey.includes('date') && ['desc']);

  const sortedFolders = orderBy(folders, sortKey, sortKey.includes('date') && ['desc']);

  return (
    <>
      <main className={classes.main}>
        <Box
          className={classes.hero}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Container
            className="inner"
            style={{
              backgroundImage: `url(${folder.background_image_url})`,
              backgroundColor: getFolderColor(folder.background_color)
            }}
            maxWidth={"md"}
          >
            <Box
              marginBottom={1}
              zIndex={1}
            >
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={12} md="auto">
                  <Typography variant="h5" color="inherit">
                    {folder.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Typography variant="body2" color="inherit" style={{ fontWeight: 300 }}>
                    Last changed: {dayjs(folder.date_created.toDate()).format('MM/DD/YYYY, hh:mm A')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box zIndex={1}>
              <Typography variant="body1" color="inherit">
                {folder.description}
              </Typography>
            </Box>
          </Container>
          <Container maxWidth={"md"}>
            <Grid container spacing={0} alignItems="center" style={{ padding: "8px 0 " }}>
              <Grid item sm={2} xs={4}>
                <IconButton color="primary" onClick={changeView("card")}>
                  <ViewComfyIcon />
                </IconButton>
                <IconButton color="primary" onClick={changeView("list")}>
                  <ListIcon />
                </IconButton>
              </Grid>
              <Grid item sm={8} xs={4} md={8}>
                <Button startIcon={<SortIcon />} {...bindTrigger(popupState)}>
                  Sort by: {SortLabels[sortKey]}
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem key="name" onClick={onSort('name')}>Name</MenuItem>
                  <MenuItem onClick={onSort('date_created')}>Created</MenuItem>
                  <MenuItem onClick={onSort('date_last_modified')}>Modified</MenuItem>
                  <MenuItem onClick={onSort('note_type')}>Type</MenuItem>
                </Menu>
              </Grid>
              <Grid item sm={2} xs={4} md={2} style={{ textAlign: "right" }}>
                <Button
                  onClick={() => setShowNotifModal(true)}
                  startIcon={<NotificationsNoneIcon />}
                >
                  Updates
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container maxWidth="md">
          {view === "list" ? (
            <ListView
              onSelectFolder={onFolderSelect}
              folders={sortedFolders} files={sortedDocs} loading={loadingFolders} />
          ) : (
            <CardView
              onSelectFolder={onFolderSelect}
              folders={sortedFolders} files={sortedDocs} loading={loadingFolders} />
          )}
        </Container>
      </main>
      {showNotifModal && (
        <NotificationSettings
          folderId={folderId}
          handleClose={() => setShowNotifModal(false)} />
      )}
    </>
  );
};

export default FileBrowser;
