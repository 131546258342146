import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function getDevicesOfKind(deviceInfos, kind) {
  return deviceInfos.filter(function (deviceInfo) {
    return deviceInfo.kind === kind;
  });
}

function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then(function (devices) {
      return devices.every(function (device) {
        return !(device.deviceId && device.label);
      });
    })
    .then(function (shouldAskForMediaPermissions) {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(function (mediaStream) {
            mediaStream.getTracks().forEach(function (track) {
              track.stop();
            });
          });
      }
    });
}

function getDeviceSelectionOptions() {
  // before calling enumerateDevices, get media permissions (.getUserMedia)
  // w/o media permissions, browsers do not return device Ids and/or labels.
    return navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function () {
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                console.log("enumerateDevices() not supported.");
                return [];
            }
            return ensureMediaPermissions().then(function () {
                return navigator.mediaDevices
                    .enumerateDevices()
                    .then(function (deviceInfos) {
                        var kinds = ["audioinput", "audiooutput", "videoinput"];
                        return kinds.reduce(function (deviceSelectionOptions, kind) {
                            deviceSelectionOptions[kind] = getDevicesOfKind(deviceInfos, kind);
                            return deviceSelectionOptions;
                        }, {});
                    });
            });
        }).catch(e => {
            console.log(e);
        });
}

const mapOptions = (options) =>
  options.map((i) => ({
    label: i.label,
    value: i.deviceId,
  }));

const SettingsModal = ({ onClose, onSubmit, settings: initialSettings }) => {
  const [settings, setSettings] = useState(
    initialSettings || {
      audioinput: "default",
      audiooutput: "default",
      videoinput: "default",
    }
  );
  const [devices, setDevices] = useState({
    audioinput: [],
    audiooutput: [],
    videoinput: [],
  });

  useEffect(() => {
    getDeviceSelectionOptions().then((resp) => {
      setDevices(resp);
      if (!initialSettings) {
        setSettings({
          videoinput: resp.videoinput[0]?.deviceId,
          audiooutput: resp.audiooutput[0]?.deviceId,
          audioinput: resp.audioinput[0]?.deviceId,
        });
      }
    });
  }, []);

  const onSave = () => {
    onSubmit(settings);
  };

  return (
    <Dialog open fullWidth onClose={onClose} maxWidth="sm">
      <DialogTitle>Configure devices</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          style={{ marginBottom: 24 }}
          margin="normal"
          label="Camera"
          value={settings.videoinput}
          onChange={(e) =>
            setSettings({ ...settings, videoinput: e.target.value })
          }
        >
          {mapOptions(devices.videoinput).map((i) => (
            <MenuItem value={i.value} key={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </Select>
        <Select
          fullWidth
          margin="normal"
          style={{ marginBottom: 24 }}
          label="Microphone"
          value={settings.audioinput}
          onChange={(e) =>
            setSettings({ ...settings, audioinput: e.target.value })
          }
        >
          {mapOptions(devices.audioinput).map((i) => (
            <MenuItem value={i.value} key={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsModal;
