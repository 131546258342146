import React, { useEffect } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import dayjs from "dayjs";
import FolderIcon from "../FolderIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ItemContextMenu from "../ItemContextMenu";
import { useDrag, useDrop } from "react-dnd";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import {getEmptyImage, NativeTypes} from "react-dnd-html5-backend";

const useStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: theme.spacing(4),
  },
  icon: {
    position: "absolute",
    width: 16,
    bottom: 2,
    right: 2,
  },
  listItem: (props) => ({
    "& .MuiListItem-button": {
      backgroundColor:
        props.isActive || props.selected
          ? theme.palette.primary.light
          : theme.palette.background.paper,
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "& .contextMenu": {
      display: "none",
    },
    "&:hover": {
      "& .contextMenu": {
        display: "block",
      },
      "& .timestamp": {
        display: "none",
      },
    },
  }),
  iconContainer: {
    minWidth: 32,
    minHeight: 26,
    position: "relative",
    marginRight: theme.spacing(2),
    borderRadius: 3,
    border: "1px solid",
    borderColor: fade(theme.palette.text.primary, 0.2),
  },
  canDrop: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 6,
  },
}));

const FolderItem = ({ folder, onSelect, selected, selectionMode, onFolderAction, onFilesDrop, onNotesDrop }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: ["FILE", NativeTypes.FILE],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    drop(props, monitor) {
      const item = monitor.getItem();
      if (item.files) {
        // File drop
        onFilesDrop(item.files, folder.id);
      } else {
        // Note drop
        onNotesDrop(folder.id);
      }
    }
  }));

  const [collected, drag, dragPreview] = useDrag(
    () => ({
      type: "FILE",
      item: { id: folder.id },
      canDrag: () => !!selectionMode && selected,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [selectionMode, selected]
  );

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const isActive = isOver && canDrop;
  const classes = useStyles({ isActive, selected });

  const onClick = () => {
    if (selectionMode) {
      onSelect(folder);
    }
  };

  return (
    <ListItem
      divider
      button
      key={folder.id}
      selected={selected}
      component={!selectionMode && Link}
      to={`/folders/${folder.id}`}
      onClick={onClick}
      classes={{
        container: classes.listItem,
      }}
      ref={drop}
    >
      {selectionMode && (
        <ListItemIcon className={classes.listIcon}>
          {selectionMode && (
            <Checkbox
              color="primary"
              edge="start"
              checked={selected}
              disableRipple
            />
          )}
        </ListItemIcon>
      )}
      <ListItemText
        ref={drag}
        primary={
          <Box display="flex" alignItems="center">
            <Box marginRight={2}>
              <FolderIcon
                isShared={folder.is_shared}
                image={folder.background_image_url}
                color={folder.background_color}
                size="sm"
              />
            </Box>
            <Typography variant="body1">{folder.name}</Typography>
          </Box>
        }
      />
      <ListItemSecondaryAction>
        <ItemContextMenu className="contextMenu" item={folder} />
        <Typography variant="body2" color="textSecondary" className="timestamp">
          {(folder.item_count || 0) + " items"}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FolderItem;
