import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import firebase, { auth } from "lib/firebase";
import uuid from "lib/uuid";
import last from "lodash/last";
import MediaUploader from "./MediaUploader";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  section: {
    border: "2px dashed",
    borderColor: theme.palette.action.disabledBackground,
    borderRadius: 4,
    textAlign: "center",
  },
}));

const NoteTypeDocument = ({ value, onChange }) => {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [recording, setRecording] = useState();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const onFileSelect = (e) => {
    const files = e.target.files;
    const file = files[0];
    if (file) {
      setFile(file);
      uploadFile(file);
    }
  };

  const onFilesDrop = (files) => {
    const f = files[0];
    setFile(f);
    if (f) {
      uploadFile(f);
    }
  };


  const uploadFile = (f) => {
    return new Promise((resolve, reject) => {
      setUploading(true);
      const user = auth.currentUser;
      const fileid = uuid();
      const ext = last(f.name.split("."));
      const name = fileid + "." + ext;
      const storage = firebase.storage().ref(`user-content/${user.uid}/document`);
      const uploadTask = storage.child(name).put(f);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const pcDone =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(pcDone.toFixed(0));
        },
        (error) => {
          alert(error.message);
          setUploading(false);
          reject(error);
        },
        () => {
          onChange(name);
          setUploading(false);
          resolve(name);
        }
      );
    });
  };

  return (
    <Card elevation={0} className={classes.root}>
      <Box className={classes.section}>
        <MediaUploader
            placeholder="Drag your document here"
            onFilesDrop={onFilesDrop}
            onFileSelect={onFileSelect}
            progress={progress}
            uploading={uploading}
            file={file}
        />
        {file && (
            <Box display="flex" justifyContent="space-between" marginTop={4}>
              <IconButton color="primary" onClick={() => setFile()}>
                <DeleteIcon />
              </IconButton>
            </Box>
        )}
      </Box>
    </Card>
  );
};

export default NoteTypeDocument;
