import React from 'react';
import Point from './Point';
import Content from './Content';

import {
  PointSelector,
} from '../selectors'

export default {
  innerRef: () => {},

  onChange: () => {},
  onSubmit: () => {},
  type: Point.TYPE,
  selectors: [
    PointSelector,
  ],
  disableAnnotation: false,
  disableSelector: false,
  disableEditor: false,
  disableOverlay: false,
  activeAnnotationComparator: (a, b) => a === b,
  renderSelector: ({ annotation }) => {
    switch (annotation.geometry.type) {
      case PointSelector.TYPE:
        return (
          <Point
            annotation={annotation}
          />
        )
      default:
        return null
    }
  },
  renderContent: ({ key, annotation }) => (
      <Content
          key={key}
          annotation={annotation}
      />
  ),
  renderHighlight: ({ key, annotation, active }) => {
    switch (annotation.geometry.type) {
      case PointSelector.TYPE:
        return (
          <Point
            key={key}
            annotation={annotation}
            active={active}
          />
        )
      default:
        return null
    }
  },
}
