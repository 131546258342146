import React from "react";

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
    this.imageRef = React.createRef();
  }

  handleImageLoaded() {
    this.setState({ loaded: true });
  }

  render() {
    const { loaded } = this.state;
    const imageStyle = !loaded ? { display: "none" } : this.props.style;
    return (
      <div className="loadable-image">
        {!loaded && <span>Loading...</span>}
        <img
          ref={this.imageRef}
          className={this.props.className}
          src={this.props.src}
          style={imageStyle}
          onLoad={this.handleImageLoaded.bind(this)}
        />
      </div>
    );
  }
}

export default Image;
