import React, { useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ICONS from "constants/noteIcons";
import { ReactComponent as TrashIcon } from "assets/icons/delete.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import InlineEdit from "components/InlineEdit";
import ShareModal from "components/ShareModal";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: 4,
    marginRight: theme.spacing(2),
  },
  name: {
    fontWeight: 700,
  },
  inlineLabel: {
    cursor: "text",
  },
  inlineTextInput: {
    fontSize: "1.5rem",
    fontWeight: 700,
    width: "100%",
    border: "none",
    outline: "none",
    padding: 4,
  },
  iconButton: {
    padding: 8,
  },
  inlineTextArea: {
    width: "100%",
    border: "none",
    outline: "none",
    resize: "none",
    padding: 8,
    fontFamily: "inherit",
    fontSize: "1rem",
  },
  labelClassName: {
    minHeight: 55,
    display: 'block',
  }
}));

const NoteHeader = ({ note, onUpdate, onDelete, editable }) => {
  const [showShare, setShowShare] = useState(false);
  const classes = useStyles();
  const confirm = useConfirm();

  const toggleShareModal = () => {
    setShowShare(!showShare);
  };

  const updateName = (value) => {
    return onUpdate({ name: value });
  };

  const updateDescription = (value) => {
    return onUpdate({ description: value });
  };

  const handleDelete = () => {
    return confirm().then(() => onDelete(note.id));
  };

  return (
    <Box>
      <Box marginBottom={2} display="flex" alignItems="start">
        <img
          alt={note.name}
          src={ICONS[note.note_type]}
          className={classes.icon}
        />
        <Box flex={1}>
          <InlineEdit
            value={note.name}
            labelPlaceHolder={
              <Typography gutterBottom variant="h5" className={classes.name}>
                {note.name}
              </Typography>
            }
            labelClassName={classes.inlineLabel}
            inputClassName={classes.inlineTextInput}
            onSave={updateName}
          />
          <Typography paragraph variant="body2" color="textSecondary">
            Last change:{" "}
            {dayjs(note.date_last_modified.toDate()).format(
              "MM/DD/YYYY, hh:mm A"
            )}
          </Typography>
          <InlineEdit
            inputClassName={classes.inlineTextArea}
            labelClassName={classes.labelClassName}
            labelPlaceHolder={
              <Typography>
                {note.description || "Click here to add description"}
              </Typography>
            }
            value={note.description}
            onSave={updateDescription}
            multiline
          />
        </Box>
        <Box>
          {editable && (
            <Tooltip title="Edit Note">
              <IconButton
                component={Link}
                to={
                  note.folder_id
                    ? `/folders/${note.folder_id}/edit/${note.id}`
                    : `/folders/edit/${note.id}`
                }
                color="primary"
                className={classes.iconButton}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete Note">
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={handleDelete}
            >
              <TrashIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share Note">
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={toggleShareModal}
              edge="end"
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {showShare && <ShareModal onClose={toggleShareModal} note={note} />}
    </Box>
  );
};

export default NoteHeader;
