import {useMemo, useEffect} from 'react';
import {useCollectionData} from "react-firebase-hooks/firestore";
import {firestore, serverTimestamp} from "../lib/firebase";

export default (noteId) => {
    const [comments, loadingComments, commentsError] = useCollectionData(
        firestore
            .collection("Comments")
            .where("note_id", "==", noteId)
            .orderBy("created", "asc"),
        { idField: "id" }
    );

    const thread = useMemo(() => {
        if (!comments) return [];
        const nodes = {};
        return comments.filter(function(obj) {
            var id = obj["id"],
                parentId = obj["parent_comment_id"];

            nodes[id] = Object.assign(obj, nodes[id], { children: [] });
            parentId &&
            (nodes[parentId] = nodes[parentId] || { children: [] })[
                "children"
                ].push(obj);
            return !parentId;
        });
    }, [comments]);

    const addComment = ({ text, parentCommentId, noteUserId, authorId, authorName }) => {
        return firestore
            .collection("Comments")
            .doc()
            .set({
                text,
                created: serverTimestamp(),
                author_name: authorName,
                parent_comment_id: parentCommentId || null,
                note_id: noteId,
                note_user_id: noteUserId,
                author_user_id: authorId || null,
                schema_version: 1,
                deleted: null,
                read_by_user: noteUserId === authorId ? true : null,
            });
    };

    const deleteComment = (id) => firestore.collection('Comments').doc(id).delete();

    const annotations = comments?.filter(i => i.image_annotation);

    return {
        loading: loadingComments,
        error: commentsError,
        comments,
        thread,
        annotations,
        addComment,
        deleteComment,
    }
};
