import firebase, {storage, firestore, auth} from 'lib/firebase';
import uuid from "lib/uuid";
import last from "lodash/last";
import NoteTypes from 'constants/noteTypes';
import buildNoteContentObject from "./buildNoteContentObject";

function getNotePath (file) {
    if (file.type.includes('video')) {
        return 'video';
    } else if(file.type.includes('audio')) {
        return 'audio';
    } else if(file.type.includes('image')) {
        return 'image';
    } else if(file.type.includes('pdf')) {
        return 'document';
    }

    return undefined;
}

function getNoteType(type) {
    switch (type) {
        case 'video':
            return NoteTypes.Video;
        case 'audio':
            return NoteTypes.Audio;
        case 'image':
            return NoteTypes.Photo;
        case 'pdf':
            return NoteTypes.Document;
    }
}

function uploadFile (file, folderId) {
    const fileid = uuid()
    const user = auth.currentUser;
    const ext = last(file.name.split("."));
    const name = fileid + "." + ext;
    const notePath = getNotePath(file);
    if(!notePath) return; // TODO

    const storage = firebase.storage().ref(`user-content/${user.uid}/${notePath}`);
    return storage.child(name).put(file).then(
        (snapshot => {
            return  createNote(file.name, ext, name, folderId)
        })
    );
}

function createNote(filename, type, value, folderId) {
    const noteType = getNoteType(type);
    const user = auth.currentUser;
    const payload = {
        schema_version: 2,
        content: buildNoteContentObject(noteType, value, type),
        folder_id: folderId || null,
        date_last_modified: new Date(),
        user_id: user.uid,
        is_shared: false,
        tags: [],
        note_type: noteType,
        description: '',
        name: filename,
        date_created: new Date(),
        date_last_accessed: new Date(),
    };
    return firestore.collection("Notes").doc().set(payload);
}

export default async (files, folderId, onProgress, onComplete) => {
    const total = files.length;
    let completed = 1;
    for(let file of files) {
        onProgress({
            files,
            completed,
            total
        });
        await uploadFile(file, folderId);
        completed = completed + 1;
    }
    onComplete(files);
}