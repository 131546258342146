import React, { useEffect, useState } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import cx from "classnames";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Loading from 'components/Loading';
import Button from 'components/Button';

const Header = styled(DialogTitle)`
  padding-bottom: 8px;  
  .close {
    position: absolute;
    left: 10px;
    top: 8px;
  }
  .search {
    background-color: rgba(240, 240, 240, .5);
  }
`;
const Root = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: 33.33%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    height: 120px;
    border: 3px solid transparent;
    &.selected {
      border-color: rgba(245, 166, 35, 1);
    }
  }
`;

const UnsplashSearch = ({ onClose }) => {
  const [query, setQuery] = useState("idea");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();

  useEffect(() => {
    onSearch();
  }, []);

  const onSearch = (e) => {
    e && e.preventDefault();
    setLoading(true);
    return axios
      .get(
        `https://api.unsplash.com/search/photos?page=1&per_page=100&query=${query}&orientation=landscape`,
        {
          params: {
            client_id: "mddmrGKz-W2aGbm8HJnVi_aVT9gaAKTS_xMIYBiifws",
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        setResults(data.results);
      })
      .catch((e) => {
        console.log(e.response.data);
        setLoading(false);
      });
  };

  return (
    <Dialog open maxWidth="sm" fullWidth scroll="paper">
        <Header disableTypography>
            <Box textAlign="center">
                <IconButton className="close" onClick={() => onClose(selected)}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                    Select an image
                </Typography>
                <Box mt={1}>
                    <TextField
                        fullWidth
                        className="search"
                        margin={"none"}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button onClick={onSearch} color="primary">Search</Button>
                                </InputAdornment>
                            )
                        }}
                        variant={"outlined"}
                        size={"small"}
                        placeholder="Search for images..."
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                    />
                </Box>
            </Box>
        </Header>
        <DialogContent>
            <Loading linear={false} spinning={loading}>
                <Root className="results">
                    {results.map((i) => (
                        <Box
                            className={cx("item", { selected: i === selected })}
                            key={i.id}
                            onClick={() => setSelected(i)}
                            style={{
                                backgroundImage: `url(${i.urls.thumb})`
                            }}
                        />
                    ))}
                </Root>
            </Loading>
        </DialogContent>
    </Dialog>
  );
};

export default UnsplashSearch;
