import React from "react";
import { useHistory } from "react-router";
import Box from "@material-ui/core/Box";
import FileIcon from "components/FileIcon";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { auth, firestore } from "lib/firebase";
import Divider from "@material-ui/core/Divider";
import Avatar from "components/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1),
  },
}));

const Activity = ({ comment }) => {
  const classes = useStyles();
  const history = useHistory();
  const [note, loading] = useDocumentDataOnce(
    firestore.collection("Notes").doc(comment.note_id)
  );

  const markRead = () => {
    firestore
      .collection("Comments")
      .doc(comment.id)
      .update({
        read_by_user: true,
      })
      .catch(console.log);
      history.push(`/notes/${comment.note_id}`);
  };

  if (!note) return null;

  return (
    <Box
      onClick={markRead}
      style={{ cursor: "pointer" }}
      mt={1}
      display="block"
      color="inherit"
    >
      <Box display="flex" mb={1}>
        <Box mr={2}>
          <Box width={100} height={100} borderRadius={6} overflow="hidden">
            <FileIcon doc={note} />
          </Box>
        </Box>
        <Box flex={1}>
          <Box display="flex" alignItems="center" mb={1}>
            <Avatar
              name={comment.author_name}
              src={comment.author_image_url}
              className={classes.avatar}
            />
            <Typography style={{ marginRight: "auto" }}>
              {comment.author_name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {dayjs(comment.created?.toDate()).format("MMMM DD, YYYY h:mm A")}
            </Typography>
          </Box>
          <Typography variant="body1">{comment.text}</Typography>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default Activity;
