import React from "react";
import { useDragLayer } from "react-dnd";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import ICONS from "../../constants/noteIcons";

const getItemStyles = (currentOffset) => {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;
  return {
    transform: `translate(${x}px, ${y}px)`,
  };
};

const useStyles = makeStyles((theme) => ({
  chip: {
    // backgroundColor: theme.pa
  },
}));

const CustomPreview = ({ style, files }) => {
  const classes = useStyles();
  return (
    <div style={style}>
      {files.map((i) => (
        <Box key={i.id} marginBottom={0.5}>
          <Chip
            avatar={<Avatar src={ICONS[i.note_type]} />}
            size="small"
            label={i.name}
            color="primary"
          />
        </Box>
      ))}
    </div>
  );
};

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

const CustomDragLayer = ({ selections }) => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset)}>
        <CustomPreview files={selections} />
      </div>
    </div>
  );
};

export default CustomDragLayer;
