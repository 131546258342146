import React, { useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import {useDownloadURL} from "react-firebase-hooks/storage";
import {storage} from "lib/firebase";
import Loading from "components/Loading";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ViewSDKClient from '../../../lib/adobePDFClient';

const PDFNote = ({ doc }) => {
    const [url, loading] = useDownloadURL(
        storage.ref(`/user-content/${doc.user_id}/document/${doc.content.file_id}`)
    );

    useEffect(() => {
        if (url) {
            const viewSDKClient = new ViewSDKClient();
            viewSDKClient.ready().then(() => {
                viewSDKClient.previewFile("adobe-dc-view", url, {
                    metaData:  {
                        /* file name */
                        fileName: doc.name,
                        /* file ID */
                        id: doc.id,
                    }
                });
            });
        }
    }, [url]);

    if (loading) return <Loading />;

    return (
        <Card>
            <CardContent>
                <div style={{ height: 600 }} id="adobe-dc-view"></div>
            </CardContent>
        </Card>
    )
}

export default PDFNote;