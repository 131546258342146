import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ReactComponent as AddFolderIcon } from "assets/icons/add_folder.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import Button from "components/Button";
import MoveDialog from "../MoveDialog";
import NewFolder from "../CreateFolder";

const FolderSelectionActionsBar = ({
  selections,
  toggleCheckAll,
  allSelected,
  selectedCount,
  toggleSelectionMode,
  onMoveItems,
  onCreateFolder,
}) => {
  const [showMove, setShowMove] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const toggleMove = () => {
    setShowMove(!showMove);
  };

  const moveItems = async (destination) => {
    await onMoveItems(destination, selections);
    toggleMove();
  };

  const handleCreateFolder = async (data) => {
    const folderId = await onCreateFolder(data);
    await onMoveItems(folderId, selections);
    setShowCreate(false);
  };

  return (
    <Box marginBottom={2}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 4 }}
      >
        <Grid item md={3}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={allSelected}
                indeterminate={selectedCount && !allSelected}
                onChange={toggleCheckAll}
              />
            }
            label={
              selectedCount
                ? `${selectedCount} ${
                    selectedCount === 1 ? "Note" : "Notes"
                  } Selected`
                : "Select All Notes"
            }
          />
        </Grid>
        <Grid item md={7}>
          <Button onClick={toggleMove} startIcon={<ArrowForwardIcon />}>
            Move to Folder...
          </Button>
          <Button
            style={{ marginLeft: 8, marginRight: 8 }}
            startIcon={<AddFolderIcon />}
            onClick={() => setShowCreate(true)}
          >
            Move to New Folder
          </Button>
          <Button startIcon={<DeleteIcon />}>Delete</Button>
        </Grid>
        <Grid item md={2} style={{ textAlign: "right" }}>
          <Button onClick={toggleSelectionMode}>Cancel</Button>
        </Grid>
      </Grid>
      <Divider />
      {showMove && (
        <MoveDialog
          count={selectedCount}
          onMove={moveItems}
          onClose={toggleMove}
        />
      )}
      {showCreate && (
        <NewFolder
          onClose={() => setShowCreate(false)}
          title="Create Folder"
          onSave={handleCreateFolder}
        />
      )}
    </Box>
  );
};

export default FolderSelectionActionsBar;
