import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ShareIcon from "@material-ui/icons/Share";
import FolderIcon from "assets/folder_mask.svg";
import Loading from "components/Loading";
import { storage } from "lib/firebase";
import { getFolderColor } from "constants/colors";
import ICONS from "constants/noteIcons";
import NoteTypes from "constants/noteTypes";
import getImageFromNote from "utils/getImageFromNote";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(4),
    "& a": {
      display: "inline-block",
      cursor: "pointer"
    }
  },
  cover: {
    width: 100,
    height: 100,
    boxShadow: "0 0 1px 2px rgba(0,0,0,.1)",
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    fontSize: 10,
    color: theme.palette.text.primary,
  },
  badge: {
    position: "absolute",
    bottom: 8,
    right: 8
  },
  name: {
    lineHeight: 1,
    marginBottom: 4
  },
  folder: {
    position: "relative",
    height: 100,
    width: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(1),
    "& a": {
      display: "flex",
      borderRadius: 4,
      overflow: "hidden",
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: 2,
        right: 0,
        width: 100,
        height: 76,
        borderRadius: 4,
        background:
          "linear-gradient(135deg, rgba(255, 255, 255, 0)30%, rgba(0, 0, 0, 0.1)100%)"
      }
    }
  },
  shareIcon: {
    position: "absolute",
    bottom: 10,
    left: 75,
    fontSize: 20,
    color: "#1C4543"
  },
  folderIcon: {
    width: "100%",
    height: 80
  }
}));

const FilePreview = ({ doc }) => {
  const [url, setUrl] = useState();
  useEffect(() => {
    const image = getImageFromNote(doc);
    if (image) {
      storage
        .ref(`user-content/${doc.user_id}/image/${image}`)
        .getDownloadURL()
        .then(resp => {
          setUrl(resp);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, []);

  if (doc.note_type === NoteTypes.Text) {
    return (
      <div
        style={{ padding: 4 }}
        dangerouslySetInnerHTML={{ __html: doc.content.text.html_text }} />
    )
  }

  if (url) {
    return (
      <div style={{
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${url})`
      }} />
    )
  }
  return null;
};

const CustomFolderIcon = ({ style, src }) => {
  return <img style={style} src={src} />;
};

const CardView = ({ folders, loading, files = [], onSelectFolder }) => {
  const classes = useStyles();

  if (loading) return <Loading />;

  return (
    <Grid container>
      {folders.map(i => (
        <Grid item md={2} sm={3} xs={4} key={i.id}>
          <Box className={classes.card}>
            <div className={classes.folder}>
              <a onClick={() => onSelectFolder(i)}>
                {i.background_image_url ? (
                  <CustomFolderIcon
                    src={i.background_image_url}
                    style={{
                      color: getFolderColor(i.background_color),
                      maskBorder: `url(${FolderIcon})`,
                      WebkitMaskBoxImage: `url(${FolderIcon})`,
                      'mask-image': `url(${FolderIcon})`,
                      'mask-size': 'contain',
                      'mask-repeat': 'no-repeat',
                      height: 80,
                      width: "100%",
                    }}
                  />
                ) : (
                  <svg width="105px" height="87px" viewBox="0 0 105 87">
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        transform="translate(-448.000000, -97.000000)"
                        fill={getFolderColor(i.background_color)}
                      >
                        <g
                          id="Tint"
                          transform="translate(448.000000, 97.000000)"
                        >
                          <path
                            d="M4.375,0 L31.3541667,0 C32.7312299,0 34.0279288,0.645361694 34.8541667,1.74193548 L40.9791667,6.87096774 C41.8054045,7.96754153 43.1021035,8.61290323 44.4791667,8.61290323 L100.625,8.61290323 C103.041246,8.61290323 105,10.562631 105,12.9677419 L105,82.6451613 C105,85.0502722 103.041246,87 100.625,87 L4.375,87 C1.95875429,87 0,85.0502722 0,82.6451613 L0,4.35483871 C0,1.94972777 1.95875429,0 4.375,0 Z"
                            id="Path"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                )}
                <ShareIcon className={classes.shareIcon} />
              </a>
            </div>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              className={classes.name}
            >
              {i.name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {dayjs(i.date_created.toDate()).format("MMMM D, YYYY")}
            </Typography>
          </Box>
        </Grid>
      ))}
      {files.map(i => (
        <Grid item md={2} sm={3} xs={4} key={i.id}>
          <Box className={classes.card}>
            <Link to={`/n/${i.id}`}>
              <div className={classes.cover}>
                <FilePreview doc={i} />
                <img src={ICONS[i.note_type]} className={classes.badge} />
              </div>
            </Link>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              className={classes.name}
            >
              {i.name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {dayjs(i.date_created.toDate()).format("MMMM D, YYYY")}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardView;
