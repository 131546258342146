import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from '@material-ui/lab/Alert';
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from "components/Button";

const LoginForm = ({ onBack, onSubmit }) => {
  const [fields, setFields] = useState({
      email: '',
      password: ''
  });
  const [error, setError] = useState();

  const onChange = (e) => {
      const { name, value } = e.target;
      fields[name] = value;
      setFields({...fields});
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      if (fields.email && fields.password) {
          setError('');
          return onSubmit(fields).catch(e => setError(e.message));
      }
  }

  return (
    <Box marginBottom={3}>
      <Box marginBottom={2}>
        <Button startIcon={<ArrowBackIcon />} onClick={onBack}>Back to all sign in options</Button>
      </Box>
        { error && (
            <Box marginBottom={2}>
                <Alert severity="error" variant="filled">Incorrect username or password</Alert>
            </Box>
        )}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label="Email"
          type="email"
          style={{ marginBottom: 10 }}
          value={fields.email}
          onChange={onChange}
          name="email"
        />
        <TextField
          fullWidth
          required
          variant="outlined"
          label="Password"
          type="password"
          style={{ marginBottom: 20 }}
          value={fields.password}
          onChange={onChange}
          name="password"
        />
        <Button type="submit" fullWidth variant="contained" color="primary" size="large">
          Login
        </Button>
      </form>
    </Box>
  );
};

export default LoginForm;
