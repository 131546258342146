import React, { Component } from "react";
import cookie from "react-cookies";
import cx from 'classnames';
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from "@material-ui/core/Typography";
import InfoIcon from '@material-ui/icons/Info';
import styled from 'styled-components';
import qs from "query-string";
import withConfirm from "hooks/withConfirm";
import Page from "components/Page";
import { Provider } from "context/annotation";
import Carousel from "components/Carousel";
import Loading from "components/Loading";
import ActionBar from "components/ActionBar";
import { serverTimestamp, storage, firestore } from "lib/firebase";
import { getAllImages } from "utils/getImageFromNote";
import AnnotationComment from "./AnnotationComment";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const Root = styled(Card)`
  position: relative;
  .img-actions {
    opacity: 0;
    transition: all .2s ease;
  }
  &:hover {
    .img-actions {
      opacity: 1;
    }    
  }
  @media all and (max-width: 768px) {
   .img-actions {
      opacity: 1;
   }
  }
  .hint {
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: rgba(50, 50, 50, .5);
    padding: 6px 12px;
    color: white;
    text-align: center;
    position: absolute;
    top: 10px;
    left: 10px;
    margin-right: 10px;
    z-index: 1;
  }
  
  &.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.9);
    z-index: 1101;
    padding-top: 40px;
    .page {
      width: calc(100vw - 150px);
    }
    .loadable-image {
      max-height: calc(100vh - 80px);
      
    }
    .img-actions {
      display: none;
    }
    .close {
      color: white;
      right: 8px;
      position: fixed;
      top: 0px;
    }
    .dot {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      cursor: pointer;
      background-color: white;
      opacity: .6;
      border: none;
      &.active {
        opacity: 1;
      }
    }
  }
`;

const getInitials = (name) => {
  const nameParts = name.split(" ");
  return nameParts.length > 1
      ? nameParts[0].charAt(0) + nameParts[1].charAt(0)
      : nameParts[0].substring(0, 2);
}

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      comments: [],
      showAllAnnotations: false,
      loading: true,
      visibility: "all",
      selectedComment: null,
      zoom: 1,
      version: null,
      pageIndex: 0,
      showOverlay: false,
      showHint: true,
      fullScreen: false,
    };
  }

  componentWillMount() {
    const { doc } = this.props;
    const query = qs.parse(window.location.search.substr(1));
    if (query.cid) {
      this.setState({
        selectedComment: query.cid
      });
    }
    Promise.all(
      getAllImages(doc).map(i =>
        storage
          .ref(`/user-content/${doc.user_id}/image/${i.image}`)
          .getDownloadURL()
      )
    ).then(resp => {
      this.setState({
        loading: false,
        pages: resp.map((i, idx) => ({
          image: i,
          annotations: this.parseAnnotations(idx),
          comments: []
        }))
      });
    });
  }

  componentDidMount() {
    cookie.save("prompt_shown", true);
  }

  componentWillUnmount() {
    // clearTimeout(() => {
    //   clearTimeout(this.overlayTimeout);
    // });
  }

  toggleFullScreen = () => {
    this.setState({ fullScreen: !this.state.fullScreen });
  };

  hideHint = () => this.setState({ showHint: false });

  onPageChange = page => {
    this.setState({
      pageIndex: page
    });
  };

  parseAnnotations = (idx) => {
    const annotations = [];
    this.props.annotations.forEach(i => {
      if (idx === i.image_annotation.image_index) {
        annotations.push({
          geometry: {
            type: "POINT",
            x: i.image_annotation.x_position,
            y: i.image_annotation.y_position,
            width: 0,
            height: 0
          },
          data: {
            id: i.id,
            text: i.text,
            name: i.author_name,
            initials: getInitials(i.author_name),
            created: i.created.toDate(),
          },
        })
      }
    });
    return annotations;
  };

  setSelectedComment = id => {
    this.setState({
      selectedComment: id
    });
  };

  onAddComment = (annotation, index) => {
    const { user_id, id } = this.props.doc;

    this.props.getCommenterName().then(name => {
      if (name) {
        const { geometry, comment } = annotation;

        const ref = firestore
          .collection("Comments")
          .doc();

        const data = {
          text: comment,
          author_name: name,
          image_annotation: {
            x_position: geometry.x,
            y_position: geometry.y,
            image_index: index,
          },
          created: serverTimestamp(),
          note_id: id,
          note_user_id: user_id,
          schema_version: 1
        };

        const scan = this.state.pages[index];
        scan.annotations = [
          ...scan.annotations,
          {
            geometry: {
              type: "POINT",
              x: data.image_annotation.x_position,
              y: data.image_annotation.y_position,
              width: 0,
              height: 0
            },
            data: {
              text: data.text,
              id: ref.id,
              name: data.author_name,
              initials: getInitials(data.author_name),
              created: new Date()
            }
          }
        ];

        this.setState({
          pages: this.state.pages
        });

        return ref
          .set(data);
      }
    });
    // Optimistic update
    this.setState({
      pages: this.state.pages
    });
  };

  deleteComment = () => {
    const { confirm, doc } = this.props;
    const { selectedComment, pageIndex, pages } = this.state;
    const { user_id, id } = doc;

    confirm(
      () => {
        const scan = pages[pageIndex];
        scan.annotations = scan.annotations.filter(
          i => i.data.id !== selectedComment
        );

        this.setState({
          pages: this.state.pages
        });

        fetch(
          `https://us-central1-thinkersnotebook.cloudfunctions.net/comments/${user_id}/${id}/${selectedComment}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            mode: "cors",
            method: "DELETE"
          }
        ).then(() => {
          this.setSelectedComment();
        });
      },
      {
        title: "Delete comment",
        message: "Are you sure you want to delete this comment?",
        cancellationText: "No"
      }
    );
  };

  hideOverlay = () => {
    this.setState({
      showOverlay: false
    });
    clearTimeout(this.overlayTimeout);
  };

  onZoom = val => {
    let zoom = 1;
    if (val === 1) {
      zoom = this.state.zoom + 0.5;
    } else if (this.state.zoom > 1) {
      zoom = this.state.zoom - 0.5;
    }
    this.setState({ zoom });
  };

  renderEditor = props => {
    const { geometry } = props.annotation;

    if (!geometry) return null;

    return (
      <AnnotationComment
        onSubmit={comment => {
          props.onSubmit({
            ...props.annotation,
            comment
          });
        }}
        onClose={props.onCancel}
      />
    );
  };

  renderDot = ({ active, idx, onClick }) => {
    return <div onClick={onClick} className={cx('dot', { active })} />
  }

  render() {
    const { showAnnotations } = this.props;
    const {
      showAllAnnotations,
      visibility,
      loading,
      selectedComment,
      zoom,
      user,
      pages,
      pageIndex,
      showOverlay,
      showHint,
      fullScreen,
    } = this.state;

    if (loading) return <Loading />;

    const image = pages[pageIndex].image;
    return (
      <Root id="document" onClick={this.hideHint} className={fullScreen && 'fullscreen'}>
        {fullScreen && (
            <IconButton
                onClick={this.toggleFullScreen}
                className="close"
            >
              <CloseIcon color="inherit" />
            </IconButton>
        )}
        {showHint && (
          <Box className="hint">
            <InfoIcon />&nbsp;<Typography variant="body2">Tap the image to leave a comment with an annotation</Typography>
          </Box>
        )}
        <Provider value={{ showAllAnnotations, image }}>
          {!fullScreen && (
              <ActionBar
                  onFullScreen={this.toggleFullScreen}
                  imageUrl={image}
              />
          )}
          <div className="container">
            {showOverlay && (
              <div className="overlay" onClick={this.hideOverlay}>
                <div>Click image to comment</div>
              </div>
            )}
            <Carousel
              pages={pages}
              activeIndex={pageIndex}
              onPageChange={this.onPageChange}
              render={props => (
                <Page
                  {...props}
                  userId={this.props.user_id}
                  imageId={this.props.doc.id}
                  onAddComment={this.onAddComment}
                  setSelectedComment={this.setSelectedComment}
                  selectedComment={selectedComment}
                  visibility={showAnnotations}
                  zoom={zoom}
                  renderEditor={this.renderEditor}
                />
              )}
              renderDot={fullScreen && this.renderDot}
            />
          </div>
        </Provider>
      </Root>
    );
  }
}

export default withRouter(withConfirm(Document));
