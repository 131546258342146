import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme) => ({
    inputRoot: {
    borderRadius: 4,
        paddingTop: '8px !important',
        paddingBottom:'8px !important',
  },
  input: {
    // padding: 12,
  },
}));

const MyChip = (props) => {
    return (
        <Chip
            color="primary"
            {...props}
        />
    );
};

export default function Tags({ options, tags, onChange }) {
  const classes = useStyles();

  const handleChange = (e, value, reason) => {
      onChange(value);
  };

  return (
      <Autocomplete
          fullWidth
          multiple
          filterSelectedOptions
          freeSolo
          onChange={handleChange}
          renderOption={(option) => option}
          options={options}
          value={tags}
          getOptionSelected={(option, value) => option === value}
          renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                  <MyChip {...getTagProps({ index })} label={option} />
              ));
          }}
          renderInput={(params) => (
              <TextField
                  {...params}
                  variant="filled"
                  placeholder="Enter optional tags"
                  InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      classes: {
                          input: classes.input,
                          root: classes.inputRoot,
                      },
                  }}
              />
          )}
      />
  );
}
