import React, { useState, useRef } from "react";

const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;

const InlineEdit = ({
  editing,
  value,
  inputClassName,
  inputPlaceHolder,
  labelPlaceHolder,
  labelClassName,
  multiline,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(editing || false);
  const [text, setText] = useState(value || "");
  const textInput = useRef();

  const isTextValueValid = () =>
    typeof text != "undefined" && text.trim().length > 0;

  const handleFocus = () => {
    if (isEditing) {
      setIsEditing(false);
      if (isTextValueValid() && text !== value) {
        onSave(text);
      }
    } else {
      setIsEditing(true);
    }
  };

  const handleChange = () => {
    if (textInput.current !== null) {
      setText(textInput?.current?.value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE && !e.shiftKey) {
      onSave(text);
      setIsEditing(false);
    } else if (e.keyCode === ESC_KEY_CODE) {
      setText(value);
      setIsEditing(false);
    }
  };

  const inputProps = {
    value: text,
    ref: textInput,
    onBlur: handleFocus,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    className: inputClassName,
    placeholder: inputPlaceHolder,
  };

  if (isEditing) {
    if (multiline) {
      return <textarea
          autoFocus
          style={{
            fontFamily: 'inherit',
            padding: 0,
            marginBottom: ".5rem",
            lineHeight: 1.334,
          }}
          {...inputProps} />;
    }
    return (
      <>
        <input
          style={{
            fontFamily: 'inherit',
            padding: 0,
            marginBottom: ".5rem",
            lineHeight: 1.334,
          }}
          type="text"
          autoFocus
          {...inputProps}
        />
      </>
    );
  }

  const labelProps = {
    className: labelClassName,
    onClick: handleFocus,
  };

  return (
    <>
      <label {...labelProps}>{labelPlaceHolder}</label>
    </>
  );
};

export default InlineEdit;
