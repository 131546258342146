import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  cursor: default;
  padding: 8px 16px;
  margin-top: 8px;
  margin-left: 8px;
  max-width: 240px;

  &:hover {
    z-index: 99999;
  }
`;

export default class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };

    this.box = React.createRef();
  }

  componentDidMount() {
    this.setState({ height: this.box.current.clientHeight });
  }

  render() {
    const { geometry, data } = this.props.annotation;
    if (!geometry) return null;

    return (
      <Container
        style={{
          position: 'absolute',
          left:
            geometry.x > 60
              ? `calc(${Math.round(geometry.x)}% - 243px)`
              : `${geometry.x}%`,
          top:
            geometry.y + geometry.height > 75
              ? `calc(${geometry.y + geometry.height}% - ${this.state.height +
                  16}px)`
              : `${geometry.y + geometry.height}%`,
          ...this.props.style,
        }}
        className={this.props.className}
        geometry={geometry}
      >
        <div ref={this.box}>
          {data.name}: {data.text}
        </div>
      </Container>
    );
  }
}

Content.defaultProps = {
  style: {},
  className: '',
};
