import algoliasearch from 'algoliasearch/lite';

const client = algoliasearch('4NZAVCW3G4', 'f56df338dfcedb040fead9299c915d20');

const notesIndex = client.initIndex('Notes');
const foldersIndex = client.initIndex('Folders');

export default {
    notesIndex,
    foldersIndex
}

export const searchItems = (query, apiKey) => {
    const client = algoliasearch('4NZAVCW3G4', apiKey);
    const queries = [
        {
            indexName: 'Notes',
            query,
            params: {
                hitsPerPage: 10,
            }
        },
        // {
        //     indexName: 'Folders',
        //     query,
        //     params: {
        //         hitsPerPage: 10,
        //     }
        // }
    ]
    return client.multipleQueries(queries).then(({ results }) => {
        return results.map(i => ({
            ...i,
            hits: i.hits.map(i => ({...i, id: i.objectID}))
        }))
    });
}