import React from 'react';
import {useTheme, withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function NamePrompt({ onClose, onSubmit }) {
  const [name, setName] = React.useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleSubmit = () => {
    cookie.save('commenter_name', name);
    onSubmit(name);
  };

  const onEntered = () => {
    if (fullScreen) {
      window.scrollTo(0, 0);
    }
  }

  return (
    <Dialog open onClose={onClose} fullScreen={fullScreen} onEntered={onEntered}>
      <DialogTitle onClose={onClose}>What's your name</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your name. This will appear next to any comments you make on this note and future notes.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          margin="normal"
          label="Your Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Box marginBottom={2} marginTop={1}>
          <Button size="large" disabled={!name} onClick={handleSubmit} color="primary" variant="contained">
            Save Settings
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
