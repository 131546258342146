import NoteTypes from "../constants/noteTypes";

export default (doc, index = 0) => {
  let image;
  switch (doc.note_type) {
    case NoteTypes.Thinkers:
      image = doc.content.scans[index].image;
      break;
    case NoteTypes.Document: {
      if (doc.content.documents) {
        image = doc.content.documents[index].image;
      } else {
        image = doc.content.thumbnail;
      }
      break;
    }
    case NoteTypes.Photo:
      image = doc.content.photos[index].image;
      break;
    case NoteTypes.Whiteboard:
      image = doc.content.whiteboards[index].image;
      break;
    case NoteTypes.Video:
      image = doc.content.video.thumbnail;
      break;
    case NoteTypes.Audio:
      image = doc.content.audio.thumbnail;
  }
  return image;
}

export const getAllImages = (doc) => {
  switch (doc.note_type) {
    case NoteTypes.Thinkers:
      return doc.content.scans;
    case NoteTypes.Document:
      return doc.content.documents;
    case NoteTypes.Photo:
      return doc.content.photos;
    case NoteTypes.Whiteboard:
      return  doc.content.whiteboards;
    default:
      return [];
  }
};
