import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-number-input/input";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import MuiPhoneInput from "components/PhoneInput";
import Button from "components/Button";
import { firestore, getMessaging } from "lib/firebase";
import useSnack from "hooks/useSnack";

const set = (key, val) => {
  try {
    localStorage.setItem(key, JSON.stringify(val));
  } catch (e) {
    console.log(e);
  }
};

const get = key => {
  try {
    const val = localStorage.getItem(key);
    if (val) return JSON.parse(val);
    return undefined;
  } catch (e) {
    console.log(e);
  }
};

const remove = key => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
};

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default function NotificationSettings({ folderId, handleClose }) {
  const [notifications, setNotifications] = useState({
    emailEnabled: false,
    smsEnabled: false,
    email: "",
    mobile: "",
    emailSubscriberId: "",
    phoneSubscriberId: "",
    pushEnabled: false,
  });

  useEffect(() => {
    const data = get(folderId);
    if (data) {
      setNotifications(data);
    }
  }, [folderId]);


  const snack = useSnack();

  const handleChange = e => {
    const { name, value } = e.target;
    setNotifications({
      ...notifications,
      [name]: value
    });
  };

  const onCheck = e => {
    const { name, checked } = e.target;
    setNotifications({
      ...notifications,
      [name]: checked
    });
  };

  const onSave = async () => {
    const stateData = notifications;
    if (notifications.email && notifications.emailEnabled) {
      let ref;
      if (!notifications.emailSubscriberId) {
        ref = firestore.collection("WebSubscribers").doc();
        stateData.emailSubscriberId = ref.id;
      } else {
        ref = firestore
          .collection("WebSubscribers")
          .doc(notifications.emailSubscriberId);
      }
      await ref.set({
        folderID: folderId,
        email: notifications.email,
        notification_type: "email"
      });
    } else if (!notifications.emailEnabled && notifications.emailSubscriberId) {
      await firestore
        .collection("WebSubscribers")
        .doc(notifications.emailSubscriberId)
        .delete();
      delete stateData.emailSubscriberId;
    }

    if (notifications.mobile && notifications.smsEnabled) {
      let ref;
      if (!notifications.phoneSubscriberId) {
        ref = firestore.collection("WebSubscribers").doc();
        stateData.phoneSubscriberId = ref.id;
      } else {
        ref = firestore
          .collection("WebSubscribers")
          .doc(notifications.phoneSubscriberId);
      }
      await ref.set({
        folderID: folderId,
        phone_number: notifications.mobile,
        notification_type: "sms"
      });
    } else if (!notifications.smsEnabled && notifications.phoneSubscriberId) {
      await firestore
        .collection("WebSubscribers")
        .doc(notifications.phoneSubscriberId)
        .delete();
      delete stateData.phoneSubscriberId;
    }
    setNotifications(stateData);
    set(folderId, stateData);
    snack.success("Notifications settings saved.");
    handleClose();
  };

  const onRequestNotification = () => {
    messaging
      .getToken()
      .then(currentToken => {
        if (currentToken) {
          const ref = firestore.collection('WebSubscribers').doc();
          ref.set({
            folderID: folderId,
            notification_type: 'push',
            token: currentToken,
          });
          snack.success('Push notification enabled');
          const newState = {
            ...notifications,
            pushEnabled: true
          };
          setNotifications(newState);
          set(folderId, newState);
        } else {
          // Show permission request.
          console.log(
            "No Instance ID token available. Request permission to generate one."
          );
          // Show permission UI.
        }
      })
      .catch(err => {
        snack.error(err.message);
      });
  };

  const messaging = getMessaging();

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Update Notifications</DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body1" color={"textSecondary"}>
          Receive notifications when one of the items in this folder changes, is
          updated, or receives any comments.
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              color={"primary"}
              checked={notifications.emailEnabled}
              onChange={onCheck}
              name="emailEnabled"
            />
          }
          label="Send Email Notification"
        />
        {notifications.emailEnabled && (
          <TextField
            autoFocus
            value={notifications.email}
            onChange={handleChange}
            margin="dense"
            name="email"
            placeholder="Email Address"
            type="email"
            fullWidth
          />
        )}
        <Box marginBottom={2}>
          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                checked={notifications.smsEnabled}
                onChange={onCheck}
                name="smsEnabled"
              />
            }
            label="Send SMS Notification"
          />
          {notifications.smsEnabled && (
            <PhoneInput
              inputComponent={MuiPhoneInput}
              name="mobile"
              onChange={value =>
                handleChange({ target: { name: "mobile", value } })
              }
              value={notifications.mobile}
              placeholder="Mobile Number"
              country="US"
            />
          )}
        </Box>
        { !notifications.pushEnabled && messaging && (
          <Box marginBottom={2}>
            <Button onClick={onRequestNotification} color="primary" startIcon={<NotificationsIcon />}>
              Receive push notifications
            </Button>
          </Box>
        )}
        <Button variant="contained" onClick={onSave} color="primary">
          Save changes
        </Button>
      </DialogContent>
    </Dialog>
  );
}
