import React, { useContext, useState, useEffect } from "react";
import Helmet from "react-helmet/es/Helmet";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "components/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { auth, firestore } from "lib/firebase";
import AppBar from "components/AppBar";
import Loading from "components/Loading";
import SessionContext from "../../context/session";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Activity from "./components/Activity";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 70,
    height: 70,
  },
}));

const Activities = ({}) => {
  const classes = useStyles();
  const { user } = useContext(SessionContext);
  const [
    comments,
    loadingComments,
    commentsError,
  ] = useCollectionData(
    firestore
      .collection("Comments")
      .where("note_user_id", "==", user.id)
      .where("read_by_user", "==", null)
      .orderBy("created", "desc"),
    { idField: "id" }
  );

  const onMarkRead = async () => {
    await Promise.all(
      comments.map((i) =>
        firestore.collection("Comments").doc(i.id).update({
          read_by_user: true,
        })
      )
    );
  };

  return (
    <Box>
      <Helmet title="Activities" />
      <AppBar isRoot />
      <Container maxWidth="md" style={{ marginTop: 24 }}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography gutterBottom style={{ fontWeight: 700 }} variant="h5">
            Activities
          </Typography>
          {comments && comments.length ? (
            <Button color="primary" variant="text" onClick={onMarkRead}>
              Mark all as Read
            </Button>
          ) : null}
        </Box>
        <Loading spinning={loadingComments}>
          <Card>
            <CardContent>
              {comments &&
                comments.map((i) => <Activity comment={i} key={i.id} />)}
              {comments && !comments.length ? (
                <Typography
                  align="center"
                  variant="subtitle1"
                  color="textSecondary"
                >
                  No unread activities
                </Typography>
              ) : null}
            </CardContent>
          </Card>
        </Loading>
      </Container>
    </Box>
  );
};

export default Activities;
