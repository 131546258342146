import React, { useState } from "react";
import { Link as NavLink } from "react-router-dom";
import Helmet from "react-helmet/es/Helmet";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Button from "components/Button";
import { makeStyles } from "@material-ui/core/styles";
import { auth } from 'lib/firebase';
import useSnack from 'hooks/useSnack';
import thinkersLogo from "assets/thinkers_logo.svg";
import Footer from "../../components/Footer";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    height: "100vh",
    overflowY: "auto",
    display: "flex",
  },
  logo: {
    width: 300,
    marginBottom: theme.spacing(5),
  },
  container: {
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  divider: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

const ResetPassword = ({ history }) => {
  const classes = useStyles();
  const snack = useSnack();
  const [email, setEmail] = useState('');

  const onSubmit = () => {
   return auth.sendPasswordResetEmail(email).then(
       () => {
         snack.success('Password reset email sent to your registered email');
         history.push('/');
       },
       (e) => snack.error(e.message)
   );
  }

  return (
    <Box className={classes.root}>
      <Helmet title="Reset password" />
      <Box className={classes.container}>
        <img className={classes.logo} alt="Logo" src={thinkersLogo} />
        <Box marginBottom={2} width="100%">
          <Box marginBottom={2}>
            <TextField
              fullWidth
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              label="Reset your password"
              placeholder="john@example.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Box>
          <Button fullWidth color="primary" variant="contained" size="large" onClick={onSubmit}>
            Reset Password
          </Button>
        </Box>
        <Box marginTop={3}>
          <Link to="/" component={NavLink}>
            Back to login
          </Link>
        </Box>
        <Box position="absolute" bottom={2}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
