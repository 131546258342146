import React from 'react';
import { hot } from 'react-hot-loader';
import {ThemeProvider} from "@material-ui/core/styles";
import Routes from './Routes';
import {SnackbarProvider} from "material-ui-snackbar-provider";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfirmProvider } from 'material-ui-confirm';
import {SnackbarComponent} from "./hooks/useSnack";
import './App.css';
import theme from "./theme";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                SnackbarProps={{ autoHideDuration: 3000 }}
                SnackbarComponent={SnackbarComponent}
            >
                <DndProvider backend={HTML5Backend}>
                    <ConfirmProvider>
                        <Routes />
                    </ConfirmProvider>
                </DndProvider>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default hot(module)(App);