import React, { useState, useEffect } from "react";
import { Link as NavLink } from 'react-router-dom';
import Helmet from "react-helmet/es/Helmet";
import {
  makeStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Link from '@material-ui/core/Link';
import LoginOptions from "./components/LoginOptions";
import { onProviderLogin, firestore, auth } from 'lib/firebase';
import useSnack from "hooks/useSnack";
import Footer from 'components/Footer';

import thinkersLogo from "assets/thinkers_logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    height: "100vh",
    overflowY: "auto",
    display: "flex",
  },
  logo: {
    width: 300,
    marginBottom: theme.spacing(5),
  },
  container: {
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  appleBtn: {
    backgroundColor: "black",
  },
  providers: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary,
    "& svg": {
      marginLeft: 4,
      marginRight: 4,
      fontSize: 16,
    },
  },
  divider: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));



const Login = ({ history }) => {
  const classes = useStyles();
  const snack = useSnack();
  const handleLogin = (type, values) => {
    return onProviderLogin(type, values).then(
        (resp) => {
          return firestore.collection('Users').doc(resp.user.uid).get().then(
              (snapshot) => {
                if (!snapshot.exists) {
                  auth.signOut();
                  snack.error('Account not found.');
                } else {
                  snack.success('Sign in successful');
                  history.push('/');
                }
              }
          )
        },
        (e) => {
          snack.error(e.message)
        }
    );
  }

  return (
    <Box className={classes.root}>
      <Helmet title="Login" />
      <Box className={classes.container}>
        <img className={classes.logo} alt="Logo" src={thinkersLogo} />
        <LoginOptions classes={classes} onLogin={handleLogin} />
        <Box textAlign="center">
          <Typography variant="body2">
            Don't have an account yet? Get the{" "}
            <Link href="https://apps.apple.com/us/app/thinkers-app-notes-and-ideas/id1477991808">
              THINKERS App
            </Link>{" "}
            to subscribe.
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <Link to="/reset-password" component={NavLink}>
          Forgot your password?
        </Link>
        <Box position="absolute" bottom={2}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
