import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import SessionContext from "context/session";
import Logo from "../../assets/thinkers_logo.svg";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { auth } from "lib/firebase";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0px 0px 6px 1px rgba(0,0,0,0.2)",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  icon: {
    height: 34,
  },
  avatar: {
    zIndex: 1,
    backgroundColor: "#FDF2E1",
    marginLeft: "auto",
  },
  chip: {
    backgroundColor: "#FDF2E1",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    zIndex: 1,
    marginLeft: "auto",
  },
  logo: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 150,
    right: 150,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      margin: 0,
      left: 0,
      "& img": {
        height: 28,
      },
    },
  },
}));

const UserAppBar = ({ parent, isRoot, children }) => {
  const classes = useStyles();
  const { user } = useContext(SessionContext);
  const history = useHistory();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("sm"));

  const settingsState = usePopupState({
    variant: "popover",
    popupId: "settings",
  });

  const onMenuItemClick = (item) => () => {
    console.log(item);
    settingsState.close();
    switch (item) {
      case "settings": {
        history.push("/settings");
        break;
      }
    }
  };

  return (
    <AppBar
      position="sticky"
      color={"light"}
      elevation="0"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        {!isRoot && (
          <Button
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => history.push(parent ? `/folders/${parent}` : "/")}
          >
            Back
          </Button>
        )}
        <a
          target={match ? "_self" : "_blank"}
          href={match ? "https://web.mythinkers.app" : "https://mythinkers.app"}
          className={classes.logo}
        >
          <img src={Logo} alt="Thinkers" className={classes.icon} />
        </a>
        <Box marginLeft="auto">
          <IconButton component={Link} to="/activities">
            <NotificationsNoneIcon />
          </IconButton>
          <Chip
            clickable
            {...bindTrigger(settingsState)}
            className={classes.chip}
            color={"primary"}
            avatar={<Avatar alt={user.name} src={user.image} />}
            label={user.name}
          />
          <Menu {...bindMenu(settingsState)}>
            <MenuItem onClick={onMenuItemClick("settings")}>
              <ListItemIcon className={classes.listIcon}>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </MenuItem>
            <MenuItem
              component="a"
              href="https://support.thinkersnotebook.com/portal/en/kb/thinkers-desktop"
              target="_blank"
              onClick={settingsState.close}
            >
              <ListItemIcon className={classes.listIcon}>
                <HelpOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Help & Support" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => auth.signOut()}>
              <ListItemIcon className={classes.listIcon}>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      {children}
    </AppBar>
  );
};

export default UserAppBar;
