import React from "react";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import { ReactComponent as BulkEditIcon } from "assets/icons/bulk_edit.svg";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { withStyles } from "@material-ui/core/styles";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: 'transparent',
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: 30,
    },
  },
  selected: {
    backgroundColor: 'transparent'
  }
}))(MenuItem);

const SortMenu = ({ onSort, sortKey, sortDir }) => {
  const popupState = usePopupState({ variant: "popover", popupId: "sortMenu" });
  const handleSort = (type) => () => {
    onSort(type);
    popupState.close();
  };

  const getIcon = (key) => {
    if (key !== sortKey) return null;
    if (sortDir === 'asc') return <ArrowUpwardIcon fontSize="small" />
    if (sortDir === 'desc') return <ArrowDownwardIcon fontSize="small" />;
    return null;
  };

  return (
    <>
      <Tooltip title="Sort Items">
        <IconButton color="primary" size="small" {...bindTrigger(popupState)}>
          <SortIcon />
        </IconButton>
      </Tooltip>
      <Menu disableAutoFocus {...bindMenu(popupState)}>
        <StyledMenuItem
          key="name"
          onClick={handleSort("name")}
          selected={sortKey === "name"}
        >
          <ListItemIcon>{getIcon('name')}</ListItemIcon>
          <ListItemText
            primary="Name"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={handleSort("date_created")}
          selected={sortKey === "date_created"}
        >
          <ListItemIcon>{getIcon('date_created')}</ListItemIcon>
          <ListItemText
            primary="Created"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={handleSort("date_last_modified")}
          selected={sortKey === "date_last_modified"}
        >
          <ListItemIcon>{getIcon('date_last_modified')}</ListItemIcon>
          <ListItemText
            primary="Modified"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={handleSort("note_type")}
          selected={sortKey === "note_type"}
        >
          <ListItemIcon>{getIcon('note_type')}</ListItemIcon>
          <ListItemText
            primary="Type"
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default SortMenu;
