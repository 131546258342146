import React, {useEffect, useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import getImageFromNote from "../../utils/getImageFromNote";
import {storage} from "../../lib/firebase";
import NoteTypes from "../../constants/noteTypes";

const useStyles = makeStyles((theme) => ({
    root: ({ size }) => ({
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: size === 'sm' ? 30 : "100%",
        height: size === 'sm' ? 24 : "100%",
        overflow: 'hidden',
        ' & p': {
            margin: 0,
            padding: 0,
            fontSize: 8,
        }
    })
}));

const FileIcon = ({ doc, size }) => {
    const classes = useStyles({ size });

    const [url, setUrl] = useState();
    useEffect(() => {
        const image = getImageFromNote(doc);
        if (image) {
            storage
                .ref(`user-content/${doc.user_id}/image/${image}`)
                .getDownloadURL()
                .then((resp) => {
                    setUrl(resp);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [doc.id, doc.content]);

    if (doc.note_type === NoteTypes.Text) {
        return (
            <div
                className={classes.root}
                style={{ padding: 4 }}
                dangerouslySetInnerHTML={{ __html: doc.content.text.html_text }}
            />
        );
    }

    if (url) {
        return (
            <div
                className={classes.root}
                style={{
                    backgroundImage: `url(${url})`,
                }}
            />
        );
    }
    return null;
};

export default FileIcon;