import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';
import 'firebase/auth';
import 'firebase/functions';

const app = firebase.initializeApp({
  apiKey: "AIzaSyDpIbBwI2zd8HvTZCJTAkY2yDUpo2v003o",
  authDomain: "thinkersnotebook.firebaseapp.com",
  databaseURL: "https://thinkersnotebook.firebaseio.com",
  projectId: "thinkersnotebook",
  storageBucket: "thinkersnotebook.appspot.com",
  messagingSenderId: "631628494364",
  appId: "1:631628494364:web:23ddf533453df0958891d9",
  measurementId: "G-77R4DBECQ2"
});

export default app;
export const firestore = firebase.firestore();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const FieldValue = firebase.firestore.FieldValue;
export const storage = firebase.storage();
export const auth = firebase.auth();
export const functions = firebase.functions();
let messaging;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey('BJADPc5lVqJiJttqwPFgkvo0tXLFjm085JYa0bLVFrDLoOi3C5zGaR2GGJlaBAo8I3cDVQ3y_triMOvo5kwBdrc');
}

export const getMessaging = () => messaging;


export const onProviderLogin = (providerName, values) => {
  let provider;
  switch (providerName) {
    case 'apple': {
      provider = new firebase.auth.OAuthProvider('apple.com');
      break;
    }
    case 'google': {
      provider = new firebase.auth.GoogleAuthProvider();
      break;
    }
    case 'facebook': {
      provider = new firebase.auth.FacebookAuthProvider();
      break;
    }
    case 'email': {
      return auth.signInWithEmailAndPassword(values.email, values.password);
    }
  }
  return auth.signInWithPopup(provider);
}