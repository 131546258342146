import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyUploadAdapter from "../../../lib/ckFirestoreUploader";

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader)
    }
}

const NoteTypeText = ({ value, onChange }) => {
    return (
        <>
            <CKEditor
                editor={ ClassicEditor }
                data={value || ''}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    onChange(data);
                    // console.log(ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ))
                } }
                config={{
                    extraPlugins: [MyCustomUploadAdapterPlugin],
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'blockQuote',
                            'insertTable',
                            '|',
                            'imageUpload',
                            'undo',
                            'redo',
                        ]
                    },
                    // removeButtons: 'Source,Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteFromWord,PasteText,Find,Replace,SelectAll,Scayt,Superscript,Subscript,Strike,CopyFormatting,RemoveFormat,Outdent,Indent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,Table,TableToolbar,HorizontalRule,SpecialChar,PageBreak,Iframe,Font,Maximize,ShowBlocks,About,MediaEmbed'
                }}
            />
        </>
    )
};

export default NoteTypeText;