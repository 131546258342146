import React, { useContext } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Tooltip from '@material-ui/core/Tooltip';
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { ReactComponent as ShareIcon } from "../../assets/icons/share.svg";
import { ReactComponent as UpdatesIcon } from "../../assets/icons/updates.svg";
import { ReactComponent as AddFolderIcon } from "../../assets/icons/add_folder.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { ReactComponent as BulkEditIcon } from "../../assets/icons/bulk_edit.svg";
import Toolbar from "@material-ui/core/Toolbar";
import SessionContext from 'context/session';
import LayoutSwitch from "../LayoutSwitch";
import SortMenu from "../SortMenu";

const useStyles = makeStyles((theme) => ({
  toggle: {
    border: "none",
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  search: {
    backgroundColor: fade(theme.palette.text.hint, .03)
  }
}));

const FolderActionsBar = ({
  changeView,
  currentView,
  sortKey,
  sortDir,
  onSort,
  selectionMode,
  toggleSelectionMode,
  onShare,
  onNotification,
  query,
  onQueryChange,
}) => {
  const classes = useStyles();

  return (
    <Toolbar>
      <Container maxWidth={"md"} disableGutters>
        <Grid container spacing={0} alignItems="center" justify="space-between">
          <Grid item sm={4} xs={12} md={3}>
            <Box display="flex" alignItems="center">
              <LayoutSwitch changeView={changeView} view={currentView} />
            </Box>
          </Grid>
          <Grid item sm={8} xs={12} md={4}>
            <TextField
              fullWidth
              className={classes.search}
              margin={"none"}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
              variant={"outlined"}
              size={"small"}
              placeholder="Search for notes or folders..."
              value={query}
              onChange={e => onQueryChange(e.target.value)}
            />
          </Grid>
          <Grid item sm={6} xs={12} md={3}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <SortMenu onSort={onSort} sortKey={sortKey} sortDir={sortDir} />
              {onShare && (
                <Tooltip title="Share Folder">
                  <IconButton size="small" onClick={onShare} color="primary">
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              )}
              {onNotification && (
                <Tooltip title="Notifications">
                  <IconButton size="small" onClick={onNotification} color="primary">
                    <UpdatesIcon />
                  </IconButton>
                </Tooltip>
              )}
              {onShare ||
                (onNotification && (
                  <Divider
                    orientation="vertical"
                    style={{ height: 20, marginLeft: 8, marginRight: 8 }}
                  />
                ))}
              <ToggleButton
                selected={selectionMode}
                size="small"
                className={classes.toggle}
                onClick={toggleSelectionMode}
              >
                <Tooltip title="Bulk Move/Delete">
                  <BulkEditIcon />
                </Tooltip>
              </ToggleButton>
              {/*<IconButton size="small">*/}
              {/*  <AddFolderIcon />*/}
              {/*</IconButton>*/}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Toolbar>
  );
};

export default FolderActionsBar;
