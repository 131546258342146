import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      light: "rgba(253, 239, 216, 1)",
      main: "rgba(232, 129, 36, 1)",
      dark: "rgba(245, 166, 35, 1)",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: '#FDEFD8'
        },
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      containedPrimary: {
        backgroundColor: '#FDEFD8',
        color: 'rgba(232, 129, 36, 1)',
      },
    },
    MuiIconButton: {
      root: {
        // borderRadius: 6,
        // '&:hover': {
        //   backgroundColor: '#FDEFD8',
        //   color: 'rgba(232, 129, 36, 1)'
        // },
      }
    },
    MuiToggleButton: {
      root: {
        "&$selected": {
          color: 'rgba(245, 166, 35, 1)',
          backgroundColor: '#FDEFD8'
        },
        '&:hover': {
          backgroundColor: '#FDEFD8',
          color: 'rgba(232, 129, 36, 1)'
        },
      },
      sizeSmall: {
        padding: 3,
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    }
  },
  props: {
    MuiButton: {
      disableElevation: true,
    }
  }
});

export default theme;
