import React from 'react';
import cookie from "react-cookies";
import Annotation from "../Annotation";
import {Provider} from "context/annotation";

class Page extends React.Component {
  state = {
    type: 'POINT',
    annotation: {
      name: {
        text: cookie.load('commenter_name') || '',
      }
    },
  };

  onChange = annotation => {
    this.setState({annotation});
  };

  onSubmit = (values) => {
    this.props.onAddComment(values, this.props.index);
    this.onCancel();
  };

  onCancel = () => {
    this.setState({
      annotation: {},
    });
  };

  render() {
    const { data, selectedComment, setSelectedComment, visibility, zoom, style, renderEditor } = this.props;
    const { image, annotations, name, } = data;
    const {
      type,
      annotation,
    } = this.state;

    const commenters = [...new Set(annotations.map(a => a.data.initials))];

    return (
      <Provider value={{ commenters: commenters }}>
        <Annotation
          style={style}
          zoom={zoom}
          src={image}
          alt="Sketch Image"
          annotations={annotations}
          name={name}
          type={type}
          value={annotation}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          visibility={visibility}
          selectedComment={selectedComment}
          setSelectedComment={setSelectedComment}
          renderEditor={renderEditor}
        />
      </Provider>
    )
  }
}

export default Page;
