import React, { useEffect, useState } from "react";
import uuid from "lib/uuid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import StopIcon from "@material-ui/icons/Stop";
import VideocamIcon from "@material-ui/icons/Videocam";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { useVideoRecorder } from "hooks/useVideoRecorder";
import { milisecondsToPretty } from "utils";
import VideoSettingsModal from "./VideoSettingsModal";

const VideoRecorder = ({ setFile }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    setCanvas,
    duration,
    toggleRecording,
    recording,
    error,
    cleanup,
    devices,
    setDevices,
  } = useVideoRecorder({
    handleFinishRecording: (blob) => {
      const newFile = new File([blob], `${uuid()}.webm`, {
        type: "video/webm",
      });
      setFile(newFile);
    },
  });

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  const onUpdateSettings = (config) => {
    console.log(config);
    setShowModal(false);
    setDevices(config);
  };

  return (
    <Box paddingBottom={3}>
      {error && <Alert severity="error">{error}</Alert>}
      <Box marginBottom={2}>
        <div className="video-inner">
          {recording && (
            <div className="video-wrapper">
              <video
                autoPlay
                id="capture-video"
                muted="muted"
                ref={setCanvas}
                className="capture-video"
                width="100%"
              />
            </div>
          )}
        </div>
      </Box>
      <Box marginBottom={3}>
        <Fab color="secondary" onClick={toggleRecording} disabled={error}>
          {recording ? <StopIcon /> : <VideocamIcon />}
        </Fab>
      </Box>
      <Typography>
        {recording
          ? milisecondsToPretty(duration)
          : "Or start recording using your attached webcam & microphone."}
      </Typography>
      {!recording && (
        <Box textAlign="center">
          <Button color="primary" onClick={() => setShowModal(true)}>
            Settings
          </Button>
        </Box>
      )}
      {showModal && (
        <VideoSettingsModal
          settings={devices}
          onSubmit={onUpdateSettings}
          onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};

export default VideoRecorder;
