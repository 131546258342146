import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

const styleSheet = (theme) => {
  return {
    spinElement: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    nestedContainer: {
      'position': 'relative',
      '& .spinning': {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
      },
    },
    container: {
      'opacity': 0.4,
      'position': 'relative',
      '&::after': {
        content: '" "',
        position: 'absolute',
        overflow: 'hidden',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.5,
      },
    },
    tip: {
      color: theme.palette.primary.main,
      marginTop: 5,
      textShadow: '0 1px 2px #fff',
      fontWeight: 600,
    },
  };
};

class Spin extends React.PureComponent {
  hasChildren = () => {
    return !!(this.props && this.props.children);
  };

  render() {
    const {
      spinning,
      tip,
      size = 30,
      color,
      linear,
      classes,
      children,
    } = this.props;
    const hasChildren = this.hasChildren();

    let spinElement = (
      <div className={cx(classes.spinElement, { spinning })}>
        <CircularProgress size={size} color={color} />
        {tip ? <div className={classes.tip}>{tip}</div> : null}
      </div>
    );

    if (linear) {
      spinElement = <LinearProgress />;
    }

    if (hasChildren) {
      if (!spinning) return children;
      return (
        <div className={classes.nestedContainer}>
          {spinElement}
          <div className={classes.container}>{children}</div>
        </div>
      );
    } else if (spinning) {
      return spinElement;
    }
    return null;
  }
}

Spin.propTypes = {
  spinning: PropTypes.bool,
  linear: PropTypes.bool,
  tip: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

Spin.defaultProps = {
  linear: true,
  spinning: true
};

export default withStyles(styleSheet)(Spin);
