import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { DropTarget } from 'react-dnd';

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        backgroundColor: props.isActive
            ? theme.palette.primary.light
            : "transparent",
        border: "2px solid",
        borderColor: props.isActive
            ? theme.palette.primary.main
            : "transparent",
        borderRadius: 8,
        margin: 4,
    }),
    snack: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.main,
            fontWeight: 500,
        },
    },
}));

const TargetBox = ({ canDrop, isOver, isOverCurrent, connectDropTarget, children }) => {
    const isActive = canDrop && isOverCurrent;
    const classes = useStyles({
        isActive
    });

    return connectDropTarget(
        <div className={classes.root}>
            {children}
            <Snackbar
                classes={{
                    root: classes.snack,
                }}
                open={isActive}
                message="Drop your files to upload to THINKERS"
            />
        </div>
    );
};

export default DropTarget((props) => props.accepts, {
    drop(props, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) return;
        if (props.onDrop) {
            props.onDrop(props, monitor);
        }
    },
}, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
}))(TargetBox);
