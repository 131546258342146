import React from 'react';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    inputRoot: {
        borderRadius: 4,
    },
    input: {
        padding: 12,
    }
}));

const NoteDescriptionInput = ({ value, onChange }) => {
    const classes = useStyles();

    return (
      <TextField
        fullWidth
        value={value}
        onChange={onChange}
        margin="dense"
        classes={{
          root: classes.root,
        }}
        variant="filled"
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.inputRoot,
            input: classes.input,
          },
        }}
        placeholder="Enter an optional description"
      />
    );
};

export default NoteDescriptionInput;