import React from "react";
import { NativeTypes } from 'react-dnd-html5-backend';
import Dropzone from "./Dropzone";

const FileDropzone = ({ children, accepts, onDrop }) => {
  const handleFileDrop = (item, monitor) => {
    if (monitor) {
      const files = monitor.getItem().files;
      onDrop && onDrop(files);
    }
  };

  return (
      <Dropzone accepts={[NativeTypes.FILE]} onDrop={handleFileDrop}>
        {children}
      </Dropzone>
  )
};

export default FileDropzone;