import React, { useCallback, memo, useState } from 'react';


const FileDragZone = ({ children }) => {
    const [droppedItems, setDroppedItems] = useState([]);

    function isDropped(boxName) {
        return droppedBoxNames.indexOf(boxName) > -1;
    }

    const handleDrop = useCallback((index, item) => {
        const { name } = item;
        setDroppedItems([...item, item]);
    }, [droppedItems]);

    return (
        <>
            {children}
        </>
    )
};

export default memo(FileDragZone);