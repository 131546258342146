import { useEffect } from 'react';
import {
    useDocumentData,
} from "react-firebase-hooks/firestore";
import { firestore } from "lib/firebase";

export default (noteId, folderId) => {
    const [folder, loadingFolder, folderLoadError] = useDocumentData(
        folderId && firestore.collection("Folders").doc(folderId),
        { idField: "id" }
    );
    const [note, loadingNote, noteLoadError] = useDocumentData(
        firestore.collection('Notes').doc(noteId),
        { idField: "id" }
    );

    useEffect(() => {
        firestore.collection('Notes').doc(noteId).update({
            date_last_accessed: new Date()
        });
    }, [noteId]);

    const onUpdate = (data) => firestore.collection('Notes').doc(noteId).update(data);

    const onDelete = (id) => firestore.collection('Notes').doc(noteId).delete();

    return {
        folder,
        note,
        loading: loadingFolder || loadingNote,
        error: folderLoadError || noteLoadError,
        onUpdate,
        onDelete,
    };
}