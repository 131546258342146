import React from "react";
import dayjs from "dayjs";
import { Link as NavLink } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import FileIcon from "components/FileIcon";
import ICONS from "constants/noteIcons";
import ItemContextMenu from "../ItemContextMenu";

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 500,
    fontSize: 16,
  },
  imageContainer: {
    width: 100,
    height: 100,
    borderRadius: 6,
    position: "absolute",
    overflow: "hidden",
    top: -10,
    left: -10,
    border: "1px solid",
    borderColor: theme.palette.text.disabled,
    boxShadow: "0 0 4px 2px rgb(0 0 0 / 20%)",
    '& > div': {
        backgroundColor: theme.palette.background.paper,
    }
  },
  contentContainer: {
    marginLeft: 100,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2)
  },
  close: {
    position: "absolute",
    right: 3,
    top: 3,
  },
  badge: {
      position: "absolute",
      bottom: 4,
      right: 4,
  }
}));

const Root = styled.div`
  background-color: #fff;
  min-width: 350px;
  min-height: 60px;
  z-index: 10;
  position: relative;
  filter: drop-shadow(0px 0px 2px rgba(84, 84, 84, 0.5));
  border-radius: 4px;
`;

const DetailsPopover = ({ file, onClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Root>
        <IconButton size="small" className={classes.close} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Box display="flex">
          <div className={classes.imageContainer}>
            <FileIcon doc={file} />
              <img
                  alt={file.name}
                  src={ICONS[file.note_type]}
                  className={classes.badge}
              />
          </div>
          <Box className={classes.contentContainer}>
            <Box marginBottom={1}>
              <Typography
                gutterBottom
                style={{ lineHeight: 1.2, marginRight: 16 }}
                variant="subtitle1"
              >
                {file.name}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                {dayjs(file.date_created.toDate()).format("MMMM D, YYYY")}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                {file.location.name}
              </Typography>
            </Box>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              paddingTop={0.5}
            >
              <Link component={NavLink} to={`/n/${file.id}`} className={classes.link}>
                View Note
              </Link>
              <ItemContextMenu />
            </Box>
          </Box>
        </Box>
      </Root>
    </div>
  );
};

export default DetailsPopover;
