import React from "react";
import ReactPlayer from "react-player/lazy";
import Card from '@material-ui/core/Card';
import { useDownloadURL } from "react-firebase-hooks/storage";
import Loading from "components/Loading";
import { storage } from "lib/firebase";
import getImage from "utils/getImageFromNote";

const VideoPlayer = ({ doc }) => {
  const [url, loading] = useDownloadURL(
    storage.ref(`/user-content/${doc.user_id}/video/${doc.content.video.video}`)
  );
  const [thumb] = useDownloadURL(
    storage.ref(`/user-content/${doc.user_id}/image/${getImage(doc)}`)
  );



  if (loading) return <Loading />;

  return (
    <Card style={{ padding: 10 }}>
        <div className="player-wrapper">
            <ReactPlayer
                controls
                playing
                light={thumb}
                className="react-player"
                width="100%"
                height="100%"
                url={url}
                config={{
                    file: {
                        forceVideo: true
                    }
                }}
            />
        </div>
    </Card>
  );
};

export default VideoPlayer;
