import React from "react";
import Helmet from "react-helmet/es/Helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import PublicViewer from "containers/public-viewer";
import Login from "containers/login";
import ResetPassword from "containers/reset-password";
import Announcement from "containers/announcement";
import Folder from "containers/folder";
import Note from "containers/note";
import NoteEditor from "containers/note-editor";
import Settings from 'containers/settings';
import Activities from 'containers/activities';
import AuthWrapper from "components/AuthWrapper";
import ScrollToTop from "components/ScrollToTop";

const Routes = ({}) => {
  return (
    <>
      <Helmet titleTemplate="%s - THINKERS Notebook" />
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route path="/announcements/:id" component={Announcement} />
          <Route path="/:type(n|f)/:folderId?" component={PublicViewer} />
          <AuthWrapper>
            <Route exact path={["/"]} render={(props) => <Folder {...props} isRoot />} />
            <Route exact path={"/folders/:id"} component={Folder} />
            <Route exact path={"/notes/:id"} component={Note} />
            <Route
              exact
              path={"/folders/:folderId?/new/:type"}
              component={NoteEditor}
            />
            <Route
              exact
              path={"/folders/:folderId?/edit/:id"}
              component={NoteEditor}
            />
            <Route path="/settings" component={Settings} />
            <Route path="/activities" component={Activities} />
          </AuthWrapper>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
