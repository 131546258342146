import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "components/Button";
import AppleIcon from "@material-ui/icons/Apple";
import Divider from "@material-ui/core/Divider";
import { FaAt, FaFacebook, FaGoogle } from "react-icons/fa";
import LoginForm from "./LoginForm";

const appleTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
  },
  shadows: "none",
});

const LoginOptions = ({ classes, onLogin }) => {
  const [showAll, setShowAll] = useState(false);
  const [showForm, setShowForm] = useState(false);

  if (showForm)
    return (
      <LoginForm
        onSubmit={(values) => onLogin("email", values)}
        onBack={() => setShowForm(false)}
      />
    );

  return (
    <>
      <Box marginBottom={2} width="100%">
        <ThemeProvider theme={appleTheme}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            startIcon={<AppleIcon />}
            onClick={() => onLogin("apple")}
          >
            Sign in with Apple
          </Button>
        </ThemeProvider>
      </Box>
      {showAll ? (
        <Box width="100%" marginBottom={3}>
          <Divider style={{ marginBottom: 15 }} />
          <Button
            fullWidth
            size="large"
            style={{ color: "#EB5C51", marginBottom: 10 }}
            variant="outlined"
            startIcon={<FaGoogle />}
            onClick={() => onLogin("google")}
          >
            Sign in with Google
          </Button>
          <Button
            fullWidth
            size="large"
            style={{ color: "#44619D", marginBottom: 10 }}
            variant="outlined"
            startIcon={<FaFacebook />}
            onClick={() => onLogin("facebook")}
          >
            Sign in with Facebook
          </Button>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            startIcon={<FaAt />}
            onClick={() => setShowForm(true)}
          >
            Sign in with Email
          </Button>
        </Box>
      ) : (
        <Box className={classes.providers}>
          <Box display="flex" justifyContent="center" marginBottom={0.5}>
            <FaGoogle />
            <FaFacebook />
            <FaAt />
          </Box>
          <Button color="primary" onClick={() => setShowAll(true)}>
            View other sign in options
          </Button>
        </Box>
      )}
    </>
  );
};

export default LoginOptions;
