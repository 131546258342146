import ThinkerIcon from "../assets/badges/thinkers.svg";
import AudioIcon from '../assets/badges/audio.svg';
import DocumentIcon from '../assets/badges/document.svg';
import PhotoIcon from '../assets/badges/photo.svg';
import TextIcon from '../assets/badges/text.svg';
import VideoIcon from '../assets/badges/video.svg';
import WhiteboardIcon from '../assets/badges/whiteboard.svg';

export default {
  NoteTypeThinkers: ThinkerIcon,
  NoteTypeDocument: DocumentIcon,
  NoteTypePhoto: PhotoIcon,
  NoteTypeWhiteboard: WhiteboardIcon,
  NoteTypeVideo: VideoIcon,
  NoteTypeAudio: AudioIcon,
  NoteTypeText: TextIcon,
}
