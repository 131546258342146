
const colors = {
  Blue: '#037AFB',
  Green: '#67C95A',
  Indigo: '#5957D6',
  Orange: '#F19533',
  Pink: '#E94152',
  Purple: '#AF52DE',
  Red: '#E93F33',
  Teal: '#5BC9FA',
  Yellow: '#FACD33',
  Gray: '#9F9FA5'
}

export default colors;

export const colorToLabel = (folderColor) => {
  if (!folderColor) return '';
  return folderColor.replace('FolderColor', '');
}

export const getFolderColor = (folderColor) => {
  if (!folderColor) return;
  const color = colorToLabel(folderColor);
  return colors[color];
}
