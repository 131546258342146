import React, { useState, useContext } from "react";
import { withStyles, fade } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import FilledInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as SendIcon } from "assets/icons/send.svg";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaPinterestP,
  FaReddit,
  FaFileDownload,
} from "react-icons/fa";
import Typography from "@material-ui/core/Typography";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { firestore } from 'lib/firebase';
import useSnack from "hooks/useSnack";
import SessionContext from 'context/session';

const SocialIcon = withStyles((theme) => ({
  root: {
    width: 48,
    height: 48,
    borderRadius: 12,
    color: "white",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
    fontSize: 28,
  },
}))((props) => {
  const { classes, href, children, color } = props;
  return (
    <a href={href} className={classes.root} style={{ backgroundColor: color }}>
      {children}
    </a>
  );
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingRight: 60,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {children}
      </Box>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .MuiDivider-root": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "& .MuiFormLabel-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: fade(theme.palette.text.disabled, 0.1),
    },
  },
}))(MuiDialogContent);

export default function ShareModal({ onClose, folder, note }) {
  const snack = useSnack();
  const [mail, setMail] = useState('');
  const [isShared, setIsShared] = useState( folder ? folder.is_shared : note.is_shared);

  const name = folder ? folder.name : note.name;
  const url = folder ? folder.short_link : note.short_link;

  const onShareToggle = async (e) => {
    const { checked } = e.target;
    setIsShared(checked);
    if (note) {
      const url = `https://thkapp.io/n/${note.id}`;
      note.short_link = note.short_link || url;
      await firestore.collection('Notes').doc(note.id).update({
        is_shared: checked,
        short_link: note.short_link,
      });
    } else {
      const url = `https://thkapp.io/f/${folder.id}`;
      folder.short_link = folder.short_link || url;
      await firestore.collection('Folders').doc(folder.id).update({
        is_shared: checked,
        short_link: folder.short_link,
      });
    }
  };

  const onEmailSend = () => {
    window.open(`mailto:${mail}?body=${url}`, '_blank');
  };

  return (
    <Dialog onClose={onClose} open maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>
        <Typography variant="h6">
          {folder ? "Share Folder" : "Share Note"}
        </Typography>
        <FormControlLabel
          control={<Switch checked={isShared} onChange={onShareToggle} color="primary" />}
          label={folder ? "Public Folder" : "Public Note"}
        />
      </DialogTitle>
      { isShared ? (
          <DialogContent dividers>
            <InputLabel>Copy URL</InputLabel>
            <FilledInput
                readOnly
                fullWidth
                value={url}
                margin="dense"
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboard text={url} onCopy={() => snack.success('Link copied to clipboard.')}>
                      <IconButton size="small" edge="end" color="primary">
                        <CopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                }
            />
            <Divider />
            <Box paddingTop={1} display="flex">
              { note && (
                  <Box marginRight={4} flex={1}>
                    <InputLabel>Download</InputLabel>
                    <a href="#">
                      <SocialIcon color="#e88124">
                        <FaTwitter />
                      </SocialIcon>
                    </a>
                  </Box>
              )}
              <Box>
                <InputLabel>Share on Social Networks</InputLabel>
                <SocialIcon color="#3AA2F2">
                  <FaFileDownload color="white" />
                </SocialIcon>
                <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}>
                  <SocialIcon color="#3B5998">
                    <FaFacebook />
                  </SocialIcon>
                </a>
                <a target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}>
                  <SocialIcon color="#2977B5">
                    <FaLinkedin />
                  </SocialIcon>
                </a>
                <a target="_blank" href={`https://pinterest.com/pin/create/button/?url=${url}&media=&description=${name}`}>
                  <SocialIcon color="#E83E33">
                    <FaPinterestP />
                  </SocialIcon>
                </a>
                <a target="_blank" href={`http://reddit.com/submit?url=${url}&title=${name}`}>
                  <SocialIcon color="#E94033">
                    <FaReddit />
                  </SocialIcon>
                </a>
              </Box>
            </Box>
            <Divider />
            <InputLabel>Send To</InputLabel>
            <FilledInput
                fullWidth
                value={mail}
                onChange={e => setMail(e.target.value)}
                margin="dense"
                placeholder="Enter Email Address"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton size="small" edge="end" color="primary" onClick={onEmailSend}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }
            />
          </DialogContent>
      ) : (
          <DialogContent dividers style={{ textAlign: 'center' }}>
            <Typography variant="subtitle1">Make the folder public to share.</Typography>
          </DialogContent>
      )}

    </Dialog>
  );
}
