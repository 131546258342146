import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

const Footer = () => {
    return (
        <Box padding={3} textAlign="center" margin="auto">
            <Typography
                color="textSecondary"
                variant="caption"
                component="a"
                href="https://mythinkers.app/privacy"
                target="_blank"
                style={{ marginRight: 16 }}
            >
                Privacy Policy
            </Typography>
            <Typography
                color="textSecondary"
                variant="caption"
                component="a"
                href="https://www.iubenda.com/privacy-policy/14695487/cookie-policy"
                target="_blank"
            >
                Cookie Policy
            </Typography>
            <Typography
                variant="caption"
                color="textSecondary"
                component="a"
                href="https://www.iubenda.com/terms-and-conditions/14695487"
                target="_blank"
                style={{ marginLeft: 16 }}
            >
                Terms of Service
            </Typography>
        </Box>
    )
}

export default Footer;