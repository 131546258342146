import React, { useCallback } from 'react';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useSnack = () => {
  const snack = useSnackbar();
  const show = useCallback((type) => {
    return (message, action, handler, other = {}) =>
      snack.showMessage(message, action, handler, {
        ...other,
        severity: type,
      });
  }, []);

  return {
    success: show('success'),
    error: show('error'),
    info: show('info'),
    warning: show('warning'),
  };
};

export default useSnack;

export const withSnackbar = (Component) => {
  return (props) => {
    const snack = useSnack();
    return <Component {...props} snackbar={snack} />;
  };
};

export const SnackbarComponent = ({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters,
}) => {
  return (
    <Snackbar
      {...SnackbarProps}
      action={
        action && (
          <Button color="secondary" size="small" {...ButtonProps}>
            {action}
          </Button>
        )
      }
    >
      <Alert {...customParameters}>{message}</Alert>
    </Snackbar>
  );
};
