import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { SnackbarComponent } from "hooks/useSnack";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import UserContext from "context/user";
import Footer from 'components/Footer';
import FolderBrowser from "../folder-browser";
import FileViewer from "../file-viewer";
import Logo from "assets/thinkers_logo.svg";
import LogoBulb from "assets/bulb.svg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0px 0px 6px 1px rgba(0,0,0,0.2)",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  icon: {
    height: 34,
  },
  avatar: {
    zIndex: 1,
    backgroundColor: "#FDF2E1",
    marginLeft: "auto",
  },
  chip: {
    backgroundColor: "#FDF2E1",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    zIndex: 1,
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent",
      marginRight: theme.spacing(1),
      "& .MuiAvatar-root": {
        width: 40,
        height: 40,
      },
      "& .MuiChip-label": {
        display: "none",
      },
    },
  },
  crumbItem: {
    color: theme.palette.primary.main,
  },
  logo: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 150,
    right: 150,
    textAlign: "center",
  },
}));

const PublicViewer = ({ match, history }) => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [foldersTree, setFolders] = useState({});
  const [breadcrumb, setBreadcrumb] = useState("");
  const [currentFolderId, setCurrentFolderId] = useState(match.params.folderId);

  useEffect(() => {
    const folder = foldersTree[currentFolderId];
    if (!user.id && folder) {
    }
  }, [foldersTree]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        breadcrumb,
        setBreadcrumb,
        currentFolderId,
        setCurrentFolderId,
      }}
    >
      <>
        <AppBar
          position="sticky"
          color={"light"}
          elevation="0"
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            {history.length > 2 ? (
              <Button
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            ) : null}
            <a
              target="_blank"
              href="https://mythinkers.app/"
              className={classes.logo}
            >
              <img
                src={window.innerWidth > 960 ? Logo : LogoBulb}
                alt="Thinkers"
                className={classes.icon}
              />
            </a>
            {/*<Tooltip*/}
            {/*  disableFocusListener*/}
            {/*  disableHoverListener*/}
            {/*  arrow*/}
            {/*  title={user.name}*/}
            {/*>*/}
            {/*  <Chip*/}
            {/*    className={classes.chip}*/}
            {/*    color={"primary"}*/}
            {/*    avatar={<Avatar alt={user.name} src={user.image} />}*/}
            {/*    label={user.name}*/}
            {/*  />*/}
            {/*</Tooltip>*/}
          </Toolbar>
        </AppBar>
        <Switch>
          <Route exact path="/n/:documentId" component={FileViewer} />
          <Route path="/f/:folderId" component={FolderBrowser} />
        </Switch>
        <Box padding={3} textAlign="center" maxWidth={1000} margin="auto">
          <Typography gutterBottom variant="body2" color="textSecondary">
            This site contains user submitted content, comments and opinions and
            is for informational purposes only. THINKERS Manifesto LLC disclaims
            any and all liability for the acts, omissions and conduct of any
            third parties in connection with or related to your use of the site.
            All postings and use of the content on this site are subject to the
            Terms of Service, Privacy and Cookie Policy.
          </Typography>
          <Footer />
        </Box>
      </>
    </UserContext.Provider>
  );
};

export default PublicViewer;
