import T from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import compose from "../utils/compose";
import isMouseHovering from "../utils/isMouseHovering";
import withRelativeMousePos from "../utils/withRelativeMousePos";
import Image from "./Image";
import Point from "./Point";
import defaultProps from './defaultProps';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    top: 0 !important;
    position: relative;
  }
  .loadable-image {
    //max-height: calc(100vh - 120px);
  }
`;

const Img = styled(Image)`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
`;

const Items = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Target = Items;

export default compose(
  isMouseHovering(),
  withRelativeMousePos()
)(
  class Annotation extends Component {
    static propTypes = {
      innerRef: T.func,
      onMouseUp: T.func,
      onMouseDown: T.func,
      onMouseMove: T.func,
      onClick: T.func,
      visibility: T.oneOf(["all", "none"]),
      annotations: T.arrayOf(
        T.shape({
          type: T.string,
        })
      ).isRequired,
      type: T.string,
      selectors: T.arrayOf(
        T.shape({
          TYPE: T.string,
          intersects: T.func.isRequired,
          area: T.func.isRequired,
          methods: T.object.isRequired,
        })
      ).isRequired,

      value: T.shape({
        selection: T.object,
        geometry: T.shape({
          type: T.string.isRequired,
        }),
        data: T.object,
      }),
      onChange: T.func,
      onSubmit: T.func,
      onCancel: T.func,

      activeAnnotationComparator: T.func,
      activeAnnotations: T.arrayOf(T.any),
      showAllAnnotations: T.bool,

      disableAnnotation: T.bool,
      disableSelector: T.bool,
      renderSelector: T.func,
      disableEditor: T.bool,
      renderEditor: T.func,

      renderHighlight: T.func.isRequired,
      renderContent: T.func.isRequired,

      disableOverlay: T.bool,
      renderOverlay: T.func.isRequired,

      commentorName: T.string,
      selectedComment: T.string,
      setSelectedComment: T.func,
    };
    static defaultProps = defaultProps;

    setInnerRef = (el) => {
      this.container = el;
      this.props.relativeMousePos.innerRef(el);
      this.props.innerRef(el);
    };

    getSelectorByType = (type) => {
      return this.props.selectors.find((s) => s.TYPE === type);
    };

    getTopAnnotationAt = (x, y) => {
      const { annotations } = this.props;
      const { container, getSelectorByType } = this;

      if (!container) return;

      const intersections = annotations
        .map((annotation) => {
          const { geometry } = annotation;
          const selector = getSelectorByType(geometry.type);

          return selector.intersects({ x, y }, geometry, container)
            ? annotation
            : false;
        })
        .filter((a) => !!a)
        .sort((a, b) => {
          const aSelector = getSelectorByType(a.geometry.type);
          const bSelector = getSelectorByType(b.geometry.type);

          return (
            aSelector.area(a.geometry, container) -
            bSelector.area(b.geometry, container)
          );
        });

      return intersections[0];
    };

    onTargetMouseMove = (e) => {
      this.props.relativeMousePos.onMouseMove(e);
      this.onMouseMove(e);
    };

    onTargetMouseLeave = (e) => {
      this.props.relativeMousePos.onMouseLeave(e);
    };

    onMouseUp = (e) => this.callSelectorMethod("onMouseUp", e);
    onMouseDown = (e) => this.callSelectorMethod("onMouseDown", e);
    onMouseMove = (e) => this.callSelectorMethod("onMouseMove", e);
    onClick = (e) => this.callSelectorMethod("onClick", e);

    onSubmit = (values) => {
      this.props.onSubmit(values);
    };

    callSelectorMethod = (methodName, e) => {
      // Don't allow overlapping comments
      if (methodName === "onClick") {
        const { annotations, selectedComment, setSelectedComment } = this.props;
        const intersections = annotations.map((annotation) => {
          const { geometry } = annotation;
          const selector = this.getSelectorByType("POINT");
          const x = (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) * 100;
          const y =
            (e.nativeEvent.offsetY / e.currentTarget.offsetHeight) * 100;

          return selector.intersects(
            { x, y },
            geometry,
            this.container.imageRef.current
          )
            ? annotation
            : false;
        });

        if (!intersections.every((i) => i === false)) {
          const activate = intersections.filter((i) => i !== false);

          if (selectedComment === activate[0].data.id) {
            return setSelectedComment();
          }

          return setSelectedComment(activate[0].data.id);
        }

        setSelectedComment();
      }

      if (this.props.disableAnnotation) {
        return;
      }

      if (!!this.props[methodName]) {
        this.props[methodName](e);
      } else {
        const selector = this.getSelectorByType(this.props.type);
        if (selector && selector.methods[methodName]) {
          const value = selector.methods[methodName](this.props.value, e);

          if (typeof value === "undefined") {
            if (process.env.NODE_ENV !== "production") {
              console.error(`
              ${methodName} of selector type ${this.props.type} returned undefined.
              Make sure to explicitly return the previous state
            `);
            }
          } else {
            this.props.onChange(value);
          }
        }
      }
    };

    shouldAnnotationBeActive = (annotation, top) => {
      if (this.props.selectedComment === annotation.data.id) {
        return true;
      }

      // if (this.props.activeAnnotations) {
      //   const isActive = !!this.props.activeAnnotations.find(active =>
      //     this.props.activeAnnotationComparator(annotation, active),
      //   );
      //
      //   return isActive || top === annotation;
      // } else {
      //   return top === annotation;
      // }
    };

    render() {
      const { props } = this;
      const {
        isMouseHovering,
        renderHighlight,
        renderContent,
        renderSelector,
        renderEditor,
        visibility,
        zoom,
        style,
      } = props;

      const topAnnotationAtMouse = this.getTopAnnotationAt(
        this.props.relativeMousePos.x,
        this.props.relativeMousePos.y
      );

      return (
        <Container
          style={style}
          className="annotation-container"
          ref={isMouseHovering.innerRef}
          onMouseLeave={this.onTargetMouseLeave}
        >
          <Img
            className={props.className}
            style={{
              transform: `scale(${zoom})`,
            }}
            alt={props.alt}
            src={props.src}
            draggable={false}
            ref={this.setInnerRef}
          />
          {visibility !== "none" && (
            <Items className="items">
              {props.annotations.map((annotation) => (
                <Point
                  key={annotation.data.id}
                  annotation={annotation}
                  active={this.shouldAnnotationBeActive(
                    annotation,
                    topAnnotationAtMouse
                  )}
                />
              ))}
              {!props.disableSelector &&
                props.value &&
                props.value.geometry &&
                renderSelector({
                  annotation: props.value,
                })}
            </Items>
          )}
          <Target className="target" onClick={this.onClick} />
          {props.annotations.map(
            (annotation) =>
              this.shouldAnnotationBeActive(annotation, topAnnotationAtMouse) &&
              renderContent({
                key: annotation.data.id,
                annotation: annotation,
                className: "annotation",
              })
          )}
          {!props.disableEditor &&
            props.value &&
            props.value.selection &&
            props.value.selection.showEditor &&
            renderEditor({
              annotation: props.value,
              onChange: props.onChange,
              onSubmit: this.onSubmit,
              onCancel: props.onCancel,
              name: props.name,
            })}
        </Container>
      );
    }
  }
);
