import React from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Spin from '../Loading';
import usePromise from 'hooks/usePromise';

const CustomButton = ({
  loading,
  onClick,
  ...rest
}) => {
  const [handler, isLoading = false] = usePromise(onClick, loading);
  const spinStyle = rest.fullWidth ? null : { display: 'inline-block'};

  return (
    <Spin linear={false} spinning={isLoading} size={10} style={spinStyle}>
      <Button
        disabled={isLoading}
        onClick={handler}
        {...rest}
      >
      </Button>
    </Spin>
  )
};


export default styled(CustomButton)`
  position: relative;
  button {
    margin: 8px;
  }
  .icon-left {
    margin-right: 8px;
  }
  .icon-right {
    margin-left: 8px;
  }
  .progress {
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`;
