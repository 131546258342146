import React, { useState, useEffect } from "react";
import GoogleMap from "google-map-react";
import Marker from "./Marker";

const LOS_ANGELES_CENTER = { lat: 38.719349, lng: -101.390337 };

const MapView = ({ files }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (pos) {
        console.log(pos);
      });
    }
  }, []);

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    files.filter(i => i.location).forEach((place) => {
      bounds.extend(
        new maps.LatLng(
          place.location.latitude,
          place.location.longitude
        )
      );
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  return (
    <div
      style={{
        height: "calc(100vh - 130px)",
        width: "100vw",
        position: "absolute",
        left: 0,
        bottom: 0,
      }}
    >
      <GoogleMap
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={LOS_ANGELES_CENTER}
        defaultZoom={4}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
      >
        {files
          .filter((f) => f.location)
          .map((f) => (
            <Marker
              lat={f.location.latitude}
              lng={f.location.longitude}
              file={f}
              key={f.id}
              onClick={setSelected}
              selected={selected === f}
            />
          ))}
      </GoogleMap>
    </div>
  );
};

export default MapView;
