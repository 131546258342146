import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Loading from "components/Loading";
import { auth, firestore, functions } from "lib/firebase";
import SessionContext from "context/session";
import useSnack from "hooks/useSnack";

const AuthWrapper = ({ children }) => {
  const history = useHistory();
  const snack = useSnack();
  const [user, loading, error] = useAuthState(auth);
  const [searchKey, setSearchKey] = useState();
  const [loaded, setLoaded] = useState(false);
  const [userDoc, loadingUser, userError] = useDocumentData(
    user && firestore.doc(`Users/${user.uid}`),
    {
      idField: "id",
    }
  );

  useEffect(() => {
    if (user && !searchKey) {
      const fn = functions.httpsCallable("getSearchKey");
      fn().then((resp) => setSearchKey(resp.data.key));

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (pos) {
          console.log(pos);
        });
      }
    } else if (!user && searchKey) {
      setSearchKey(null);
    }
  }, [user]);

  useEffect(() => {
    if (userDoc && userDoc.account_type !== 'AccountTypeSubscribed') {
      snack.error('Only paid subscribers may access this site. Please upgrade to THINKERS + on your iPhone.');
      auth.signOut();
    }
  }, [userDoc]);

  if (user && userDoc) {
    return (
      <SessionContext.Provider value={{ user: userDoc, searchKey }}>
        {children}
      </SessionContext.Provider>
    );
  }

  if (error || userError) {
    return (
      <Box>
        <Alert variant={"filled"} severity={"error"}>
          {error?.message || userError?.message}
        </Alert>
      </Box>
    );
  } else if (!userDoc && !user && !loading) {
    history.push("/login");
  }

  return <Loading linear spinning />;
};

export default AuthWrapper;
