import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import FolderIcon from "@material-ui/icons/Folder";
import Loading from "components/Loading";
import dayjs from "dayjs";
import ICONS from "constants/noteIcons";
import { getFolderColor } from "constants/colors";

const useStyles = makeStyles(theme => ({}));

const ListView = ({ folders, files = [], loading, onSelectFolder }) => {
  if (loading) return <Loading />;

  return (
    <Card>
      <List disablePadding>
        {folders.map(i => (
          <ListItem divider button key={i.id} onClick={() => onSelectFolder(i)}>
            <ListItemIcon>
              <FolderIcon
                style={{
                  color: getFolderColor(i.background_color),
                  fontSize: 28
                }}
              />
            </ListItemIcon>
            <ListItemText primary={i.name} />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary">
                {dayjs(i.date_created.toDate()).format("MMMM D, YYYY")}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {files.map(i => (
          <ListItem
            divider
            button
            key={i.id}
            component={Link}
            to={`/n/${i.id}`}
          >
            <ListItemIcon>
              <img src={ICONS[i.note_type]} />
            </ListItemIcon>
            <ListItemText primary={i.name} />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary">
                {dayjs(i.date_created.toDate()).format("MMMM D, YYYY")}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default ListView;
