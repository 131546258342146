import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Button from 'components/Button';
import NoteTypeText from "../../note-editor/components/NoteTypeText";
import { firestore } from '../../../lib/firebase';
import useSnack from '../../../hooks/useSnack'

const TextNote = ({ note }) => {
    const [value, setValue] = useState(note.content.text.html_text);
    const snack = useSnack();

    const onSave = () => {
        return firestore.collection('Notes').doc(note.id).update({
            'content.text.html_text': value,
            date_last_modified: new Date(),
        }).then(
            () => snack.success('Note updated')
        );
    }

    return (
        <>
            <NoteTypeText value={value} onChange={setValue} />
            <Box marginTop={1} textAlign="right">
                <Button onClick={onSave} variant="contained" color="primary">Save</Button>
            </Box>
        </>
    )
};

export default TextNote;