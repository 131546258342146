import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Loading from "components/Loading";
import FileDropzone from "components/FileDropzone";
import { makeStyles } from "@material-ui/core/styles";
import dragIcon from "assets/drag-symbol.png";

const useStyles = makeStyles((theme) => ({
  dragIcon: {
    maxWidth: 120,
    marginBottom: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  link: {
    cursor: "pointer",
  },
}));

const MediaUploader = ({
  multiple,
  uploading,
  progress,
  onFilesDrop,
  onFileSelect,
  placeholder = "Drop your document here",
  accept,
  file
}) => {
  const classes = useStyles();
  return (
    <Loading linear={false} spinning={uploading} tip={`${progress}% uploaded`}>
      <FileDropzone accepts={accept} onDrop={onFilesDrop} multiple={multiple}>
        <Box padding={3}>
          <img src={dragIcon} className={classes.dragIcon} />
          <Typography paragraph variant="body2" color="textSecondary">
            {placeholder}, or{" "}
            <label htmlFor="media-file-upload">
              <Link component="span" className={classes.link}>
                click to upload.
              </Link>
            </label>
            <input
              multiple={multiple}
              onChange={onFileSelect}
              accept={accept}
              className={classes.input}
              id="media-file-upload"
              type="file"
            />
          </Typography>
            { file && <Typography>{file.name}</Typography>}
        </Box>
      </FileDropzone>
    </Loading>
  );
};

export default MediaUploader;
