import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";
import Button from "components/Button";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import firebase, { auth } from "lib/firebase";
import uuid from "lib/uuid";
import last from "lodash/last";
import AudioPlayer from "./AudioPlayer";
import AudioRecorder from "./AudioRecorder";
import MediaUploader from "./MediaUploader";
import Loading from "../../../components/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  section: {
    border: "2px dashed",
    borderColor: theme.palette.action.disabledBackground,
    borderRadius: 4,
    textAlign: "center",
  },
}));

const NoteTypeAudio = ({ value, onChange }) => {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [recording, setRecording] = useState();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const onFileSelect = (e) => {
    const files = e.target.files;
    const file = files[0];
    if (file) {
      setFile(file);
      onRemoveRecording();
      uploadFile(file);
    }
  };

  const onFilesDrop = (files) => {
    const f = files[0];
    setFile(f);
    if (f) {
      onRemoveRecording();
      uploadFile(f);
    }
  };

  const onRemoveFile = () => {
    setFile();
    setProgress(0);
    onChange(null);
  };

  const onRemoveRecording = () => {
    setRecording();
    onChange(null);
  };

  const onSaveRecording = () => {
    onRemoveFile();
    return uploadFile(recording);
  };

  const uploadFile = (f) => {
    return new Promise((resolve, reject) => {
      setUploading(true);
      const user = auth.currentUser;
      const fileid = uuid();
      const ext = last(f.name.split("."));
      const name = fileid + "." + ext;
      const storage = firebase.storage().ref(`user-content/${user.uid}/audio`);
      const uploadTask = storage.child(name).put(f);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const pcDone =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(pcDone.toFixed(0));
        },
        (error) => {
          alert(error.message);
          setUploading(false);
          reject(error);
        },
        () => {
          onChange(name);
          setUploading(false);
          resolve(name);
        }
      );
    });
  };

  return (
    <Card elevation={0} className={classes.root}>
      <Box className={classes.section} marginBottom={1}>
        {value && file ? (
          <Box padding={3}>
            <CloudDoneOutlinedIcon
              color="primary"
              className={classes.dragIcon}
            />
            <AudioPlayer audioFile={file} fileUrl={value} />
            <Box display="flex" justifyContent="space-between" marginTop={4}>
              <IconButton color="primary" onClick={onRemoveFile}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
            <MediaUploader
                onFilesDrop={onFilesDrop}
                onFileSelect={onFileSelect}
                progress={progress}
                uploading={uploading}
                accept="video/*"
            />
        )}
      </Box>
      <Box
        className={classes.section}
        padding={3}
        paddingTop={5}
        paddingBottom={1}
      >
        {recording ? (
          <Box>
            <AudioPlayer audioFile={recording} />
            <Loading linear={false} spinning={uploading} tip={`${progress}% uploaded`}>
              <Box display="flex" justifyContent="space-between" marginTop={4}>
                <IconButton color="primary" onClick={onRemoveRecording}>
                  <DeleteIcon />
                </IconButton>
                <Button
                    disabled={!!value}
                    startIcon={value && <CloudDoneOutlinedIcon color="primary" />}
                    color="primary"
                    onClick={onSaveRecording}
                >
                  { value ? 'Saved' : 'Save Recording'}
                </Button>
              </Box>
            </Loading>
          </Box>
        ) : (
          <AudioRecorder setFile={setRecording} />
        )}
      </Box>
    </Card>
  );
};

export default NoteTypeAudio;
