import React from 'react';
import styled from 'styled-components';
import FileIcon from 'components/FileIcon';
import ICONS from "constants/noteIcons";
import DetailsPopover from "./DetailsPopover";

const Wrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  padding: 2px;
  background-color: #fff;
  border-radius: 6px;
  user-select: none;
  //overflow: hidden;
  transform: translate(-50%, -50%);
  cursor: pointer;
  filter: drop-shadow(0px 0px 2px rgba(84, 84, 84, 0.5));
  &:hover {
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #fff transparent;
    border-width: 6px 8px 0;
    bottom: -6px;
    left: 79%;
    margin-left: -20px;
  }
  .badge {
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 20px;
  }
  > div {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    font-size: 8px;
    line-height: 1;
  }
  p {
    margin: 0;
  }
`;

const Marker = ({ file, onClick, selected }) => {
    if (selected) {
        return (
            <DetailsPopover file={file} onClose={() => onClick()} />
        );
    }

    return (
        <Wrapper onClick={() => onClick(file)}>
            <FileIcon doc={file} size="sm" />
            <img
                alt={file.name}
                src={ICONS[file.note_type]}
                className="badge"
            />
        </Wrapper>
    );
}

Marker.defaultProps = {
    onClick: null,
};

export default Marker;