import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as GridIcon } from "../../assets/icons/grid.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list.svg";
import { ReactComponent as EarthIcon } from "../../assets/icons/earth.svg";
import useLayout from "../../hooks/useLayout";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const LayoutSwitch = ({ changeView, view }) => {
  return (
    <StyledToggleButtonGroup
      size="small"
      exclusive
      onChange={(e, v) => v && changeView(v)}
      value={view}
    >
      <ToggleButton value="card">
        <Tooltip title="Grid View">
          <GridIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="list">
        <Tooltip title="List View">
          <ListIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="map">
        <Tooltip title="Map View">
          <EarthIcon />
        </Tooltip>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default LayoutSwitch;
