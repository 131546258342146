import React, {memo, useEffect} from "react";
import cx from "classnames";
import { useDrop, useDrag } from "react-dnd";
import { NativeTypes } from 'react-dnd-html5-backend';
import ItemContextMenu from "../ItemContextMenu";
import FolderIcon from "../FolderIcon";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import CheckIcon from "@material-ui/icons/Check";
import {getEmptyImage} from "react-dnd-html5-backend";


const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    borderRadius: 6,
    transition: "all .2s ease",
    "& a": {
      display: "inline-block",
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      "& > button": {
        display: "block",
      },
    },
  },
  folder: {
    position: "relative",
    width: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(1),
    "& a": {
      display: "flex",
      borderRadius: 4,
      overflow: "hidden",
    },
  },
  folderIcon: {
    width: "100%",
    height: 80,
  },
  menuIcon: {
    position: "absolute",
    right: 0,
    top: -4,
    display: "none",
  },
  canDrop: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 6,
  },
  checkboxContainer: {
    position: "absolute",
    zIndex: 1,
    bottom: 8,
    left: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkbox: {
    color: theme.palette.background.paper,
    fontSize: 20,
  },
  selected: {
    border: "2px solid",
    borderColor: theme.palette.primary.dark,
  },
}));

const FolderItem = ({ folder, onSelect, selectionMode, selected, onFolderAction, onFilesDrop, onNotesDrop }) => {
  const classes = useStyles();
  const history = useHistory();
  const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop(() => ({
    accept: ["FILE", NativeTypes.FILE],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    drop(props, monitor) {
      const item = monitor.getItem();
      if (item.files) {
        // File drop
        onFilesDrop(item.files, folder.id);
      } else {
        // Note drop
        onNotesDrop(folder.id);
      }
    }
  }));

  const [collected, drag, dragPreview] = useDrag(
      () => ({
        type: "FILE",
        item: { id: folder.id },
        canDrag: () => !!selectionMode && selected,
        collect: (monitor) => ({
          isDragging: monitor.isDragging()
        }),
      }),
      [selectionMode, selected]
  );

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, []);

  const onClick = () => {
    if (selectionMode) {
      onSelect(folder);
    } else {
      history.push(`/folders/${folder.id}`);
    }
  };

  const isActive = isOverCurrent && canDrop;

  return (
    <Box
      className={cx(classes.card, { [classes.canDrop]: isActive })}
      ref={drop}
    >
      <ItemContextMenu className={classes.menuIcon} item={folder} onSelect={onFolderAction} />
      <div className={classes.folder} ref={drag}>
        <a onClick={onClick}>
          {selected && (
            <div className={classes.checkboxContainer}>
              <CheckIcon className={classes.checkbox} color="primary" />
            </div>
          )}
          <FolderIcon
            isShared={folder.is_shared}
            color={folder.background_color}
            image={folder.background_image_url}
            size="lg"
          />
        </a>
      </div>
      <Typography
        variant="subtitle2"
        color="textPrimary"
        className={classes.name}
      >
        {folder.name}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {(folder.item_count || 0) + " items"}
      </Typography>
    </Box>
  );
};

export default FolderItem;
