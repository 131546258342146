import React, { useState, useContext, useEffect } from "react";
import Helmet from "react-helmet/es/Helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Loading from "components/Loading";
import AppBar from "components/AppBar";
import useNoteData from "hooks/useNoteData";
import useComments from "hooks/useComments";
import NoteHeader from "./components/NoteHeader";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/Accordion";
import ALLOWED_ANNOTATIONS from "constants/allowedAnnotations";
import Button from "@material-ui/core/Button";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import Comments from "./components/Comments";
import NoteTypes from "constants/noteTypes";
import SessionContext from "context/session";
import EditTagModal from "./components/EditTagModal";
import TextNote from "./components/TextNote";
import AudioNote from "./components/AudioNote";
import VideoNote from "./components/VideoNote";
import PDFNote from "./components/PDFNote";
import DocumentNote from "containers/file-viewer/components/Document";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const EDITABLE_NOTES = [NoteTypes.Text, NoteTypes.Photo];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  expanded: {
    margin: 0,
  },
  chip: {
    borderRadius: 6,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    marginRight: 8,
    fontWeight: 700,
    height: 28,
  },
}));

const getTranscripts = (doc) => {
  let transcripts;
  switch (doc.note_type) {
    case NoteTypes.Thinkers:
      transcripts = doc.content.scans;
      break;
    case NoteTypes.Video:
      transcripts = [
        { transcription_text: doc.content.video.transcription_text },
      ];
      break;
    case NoteTypes.Whiteboard:
      transcripts = doc.content.whiteboards;
      break;
    case NoteTypes.Photo:
      transcripts = doc.content.photos;
      break;
    case NoteTypes.Audio:
      transcripts = [
        { transcription_text: doc.content.audio.transcription_text },
      ];
      break;
    case NoteTypes.Document:
      transcripts = doc.content.documents;
      break;
  }
  return transcripts;
};

const NoteView = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();
  const { user } = useContext(SessionContext);
  const { note, folder, error, loading, onUpdate, onDelete } = useNoteData(id);
  const {
    thread,
    annotations,
    loading: loadingComments,
    addComment,
    deleteComment,
  } = useComments(id);
  const [showAnnotations, setShowAnnotations] = useState("all");
  const [editTags, setEditTags] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const toggleAnnotations = (e) => {
    e.stopPropagation();
    setShowAnnotations(showAnnotations === "all" ? "none" : "all");
  };

  const toggleEditTags = (e) => {
    e && e.stopPropagation();
    setEditTags(!editTags);
  };

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const getCommenterName = async () => {
    return user.name;
  };

  const onAddComment = (values) => {
    return addComment({
      ...values,
      authorId: user.id,
    });
  };

  const handleDelete = (id) => {
    onDelete(id);
    const path = note.folder_id ? `/folders/${note.folder_id}` : "";
    history.push(path);
  };

  if (error) return error.message;

  const renderNote = () => {
    if (note.note_type === NoteTypes.Text) {
      return <TextNote note={note} />;
    } else if (note.note_type === NoteTypes.Audio) {
      return <AudioNote doc={note} />;
    } else if (note.note_type === NoteTypes.Video) {
      return <VideoNote doc={note} />;
    } else if (note.note_type === NoteTypes.Document && note.content.file_type === 'pdf') {
      return <PDFNote doc={note} />
    }
    return (
      <DocumentNote
        user_id={user.id}
        doc={note}
        annotations={annotations}
        getCommenterName={getCommenterName}
        onFullScreen={toggleFullScreen}
      />
    );
  };

  const transcriptions = note && getTranscripts(note);

  return (
    <Box>
      <Helmet title={note?.name} />
      <AppBar parent={note?.folder_id} />
      {loading ? (
        <Loading />
      ) : (
        <Container maxWidth="md" className={classes.container}>
          <NoteHeader
              editable={EDITABLE_NOTES.includes(note.note_type)}
              note={note}
              onUpdate={onUpdate}
              onDelete={handleDelete}

          />
          <Box marginBottom={2}>
            {renderNote()}
            <div className={fullScreen && classes.fullscreen}>
              {fullScreen && (
                <div className={classes.modalHeader}>
                  <IconButton
                    onClick={toggleFullScreen}
                    className={classes.closeBtn}
                  >
                    <CloseIcon color="inherit" />
                  </IconButton>
                </div>
              )}

            </div>
          </Box>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <Box display="flex" justifyContent="space-between" flex={1}>
                <Typography variant="h6" className={classes.heading}>
                  {thread.length} Comments
                </Typography>
                {ALLOWED_ANNOTATIONS.includes(note.note_type) && (
                  <>
                    {showAnnotations === "all" ? (
                      <Button
                        onClick={toggleAnnotations}
                        color="primary"
                        startIcon={<VisibilityOffIcon />}
                      >
                        Hide Annotations
                      </Button>
                    ) : (
                      <Button
                        onClick={toggleAnnotations}
                        color="primary"
                        startIcon={<VisibilityIcon />}
                      >
                        Show Annotations
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </ExpansionPanelSummary>
            <div>
              <Divider />
              <Box padding={2}>
                <Comments
                  doc={note}
                  comments={thread}
                  loading={loadingComments}
                  getCommenterName={getCommenterName}
                  addComment={onAddComment}
                  deleteComment={deleteComment}
                />
              </Box>
            </div>
          </ExpansionPanel>
          <ExpansionPanel
            classes={{
              expanded: classes.expanded,
            }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2a-header"
            >
              <Box display="flex" justifyContent="space-between" flex={1}>
                <Typography variant="h6" className={classes.heading}>
                  Tags
                </Typography>
                <Button
                  onClick={toggleEditTags}
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  Edit Tags
                </Button>
              </Box>
            </ExpansionPanelSummary>
            <div>
              <Divider />
              {note.tags.length ? (
                <Box padding={2}>
                  {note.tags.map((i) => (
                    <Chip
                      key={i}
                      label={i}
                      color="primary"
                      className={classes.chip}
                    />
                  ))}
                </Box>
              ) : null}
            </div>
          </ExpansionPanel>
          {transcriptions ? (
              <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                  <Typography variant="h6" className={classes.heading}>
                    Transcribed Text
                  </Typography>
                </ExpansionPanelSummary>
                <div>
                  <Divider />
                  <Box padding={2}>
                    {transcriptions.map((i, idx) => (
                        <Typography
                            key={idx}
                            gutterBottom
                            variant="body1"
                            color={"textPrimary"}
                        >
                          {i.transcription_text}
                        </Typography>
                    ))}
                  </Box>
                </div>
              </ExpansionPanel>
          ) : null}
        </Container>
      )}
      {editTags && (
        <EditTagModal onClose={toggleEditTags} note={note} onSave={onUpdate} />
      )}
    </Box>
  );
};

export default NoteView;
