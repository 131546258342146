import React, { useState, useEffect, useContext } from "react";
import { useConfirm } from "material-ui-confirm";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Loading from "components/Loading";
import AppBar from "components/AppBar";
import FolderCover from "components/FolderCover";
import FolderSelectionActionsBar from "components/FolderSelectionActionsBar";
import useFolderData from "hooks/useFolderData";
import FoldersCard from "components/FoldersCard";
import FoldersList from "components/FoldersList";
import ShareModal from "components/ShareModal";
import NotificationSettings from "components/NotificationSettings";
import UserContext from "context/session";
import Helmet from "react-helmet/es/Helmet";
import FileDropZone from "components/FileDropzone";
import CustomDragLayer from "components/CustomDragLayer";
import useLayout from "hooks/useLayout";
import { firestore } from "lib/firebase";
import useSnack from "hooks/useSnack";
import Divider from "@material-ui/core/Divider";
import FolderActionsBar from "components/FolderActionsBar";
import Typography from "@material-ui/core/Typography";
import CreateFolder from "components/CreateFolder";
import MapView from "components/MapView";
import { searchItems } from "lib/algolia";
import createNoteFromFile from "utils/createNoteFromFile";
import isValidFile from "utils/isValidFiles";

const FolderView = ({
  match: {
    params: { id },
  },
  history,
  isRoot,
}) => {
  const confirm = useConfirm();
  const snack = useSnack();
  const { user, searchKey } = useContext(UserContext);
  const [view, setView] = useLayout();
  const [showAddModal, setShowAddModal] = useState(false);
  const [selections, setSelections] = useState([]);
  const [selectionMode, setSelectionMode] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [query, setQuery] = useState();
  const [searchResults, setSearchResults] = useState();
  const [uploading, setUploading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const {
    loadingFolder,
    loadingItems,
    folders,
    folder,
    notes,
    sortKey,
    sortDir,
    onSort,
  } = useFolderData(id || null, user.id);

  useEffect(() => {
    if (query) {
      searchItems(query, searchKey).then((results) => {
        setSearchResults(results[0]);
      });
    } else {
      setSearchResults(null);
    }
  }, [query]);

  const toggleSelectionMode = () => {
    setSelectionMode(!selectionMode);
    if (selectionMode) {
      setSelections([]);
    }
  };

  const toggleCheckAll = (e) => {
    if (e.target.checked) {
      setSelections(notes);
    } else {
      setSelections([]);
    }
  };

  const onFileSelect = (item) => {
    if (selections.includes(item)) {
      setSelections(selections.filter((i) => i !== item));
    } else {
      setSelections([item, ...selections]);
    }
  };

  const onSelectFolder = (folder) => {
    history.push(`/folders/${folder.id}`);
  };

  const toggleShareModal = () => {
    setShowShare(!showShare);
    setSelectedItem();
  };

  const toggleNotificationSettings = () => {
    setShowNotification(!showNotification);
  };

  const onFileAction = (action, file) => {
    switch (action) {
      case "edit": {
        if (id) {
          history.push(`/folders/${id}/edit/${file.id}`);
        } else {
          history.push(`/folders/edit/${file.id}`);
        }
        break;
      }
      case "share": {
        setSelectedItem({ note: file });
        setShowShare(true);
        break;
      }
      case "delete": {
        return confirm()
          .then(() => firestore.collection("Notes").doc(file.id).delete())
          .then(
            () => snack.success("Note deleted"),
            (e) => snack.error(e.message)
          );
      }
    }
  };

  const onFolderAction = (action, folder) => {
    console.log(action);
    switch (action) {
      case "delete": {
        return confirm()
          .then(() => firestore.collection("Folders").doc(folder.id).delete())
          .then(
            () => snack.success("Folder deleted"),
            (e) => snack.error(e.message)
          );
      }
      case "share": {
        setSelectedItem({ folder });
        setShowShare(true);
        break;
      }
      case "edit": {
        setSelectedItem({folder});
        setShowAddModal(true);
        break;
      }
    }
  };

  const onCreateFolder = async (data) => {
    if (selectedItem) {
      await firestore
        .collection("Folders")
        .doc(selectedItem.folder.id)
        .set(data, { merge: true });
      setShowAddModal(false);
      setSelectedItem();
      snack.success("Folder updated");
    } else {
      data.parent_id = id || null;
      const ref = firestore.collection("Folders").doc();
      await ref.set(data);
      snack.success("Folder created");
      setShowAddModal(false);
      return ref.id;
    }
  };

  const moveItems = async (destination, items) => {
    const changes = items.map(i => {
      if (i.note_type) {
        return {
          type: 'Notes',
          id: i.id,
          update: {
            folder_id: destination
          }
        }
      } else {
        return {
          type: 'Folders',
          id: i.id,
          update: {
            parent_id: destination,
          }
        }
      }
    });

    const batch = firestore.batch();
    changes.forEach(i => {
      batch.update(
          firestore.collection(i.type).doc(i.id),
          i.update
      )
    });
    await batch.commit();
    setSelections([]);
    setSelectionMode(false);
    snack.success('Notes moved.');
  };

  const onFileDrop = (files, folderId) => {
    if (files.length) {
      const filtered = files.filter(file => isValidFile(file));
      if (filtered.length) {
        console.log(filtered)
        createNoteFromFile(files, folderId || id, setUploading, () => {
          setUploading(false);
          snack.success("Notes created.");
        });
      } else {
        snack.error('Please select supported files.');
      }
    }
  };

  const onNotesDrop = (targetFolderId) => {
    setSelections((prev) => {
      moveItems(targetFolderId, prev);
      return prev;
    });
  }

  if (searchResults) {
    return (
      <Box>
        <Helmet title={`Search Notes`} />
        <AppBar isRoot>
          <Divider />
          <FolderActionsBar
            selectionMode={selectionMode}
            toggleSelectionMode={toggleSelectionMode}
            changeView={setView}
            currentView={view}
            onSort={onSort}
            sortKey={sortKey}
            sortDir={sortDir}
            query={query}
            onQueryChange={setQuery}
          />
        </AppBar>
        <Container maxWidth="md" style={{ marginTop: 8 }}>
          <Box>
            <Typography paragraph variant={"h5"}>
              Notes
            </Typography>
          </Box>
          {view === "card" && (
            <FoldersCard
              folder={folder}
              folders={[]}
              files={searchResults.hits}
              selectionMode={selectionMode}
              onSelect={onFileSelect}
              selections={selections}
              onFileAction={onFileAction}
            />
          )}
          {view === "list" && (
            <FoldersList
              folder={folder}
              folders={[]}
              files={searchResults.hits}
              selectionMode={selectionMode}
              onSelect={onFileSelect}
              selections={selections}
              onFileAction={onFileAction}
            />
          )}
        </Container>
      </Box>
    );
  }

  return (
    <Box>
      <Helmet title={isRoot ? 'Home' : folder?.name} />
      <AppBar parent={folder?.parent_id} isRoot={isRoot}>
        {isRoot && (
          <>
            <Divider />
            <FolderActionsBar
              selectionMode={selectionMode}
              toggleSelectionMode={toggleSelectionMode}
              changeView={setView}
              currentView={view}
              onSort={onSort}
              sortKey={sortKey}
              sortDir={sortDir}
              query={query}
              onQueryChange={setQuery}
            />
          </>
        )}
      </AppBar>
      <CustomDragLayer selections={selections} />
      {!isRoot && (
        <>
          {loadingFolder ? (
            <Loading />
          ) : (
            <FolderCover
              folder={folder}
              sortKey={sortKey}
              sortDir={sortDir}
              onSort={onSort}
              currentView={view}
              changeView={setView}
              selectionMode={selectionMode}
              toggleSelectionMode={toggleSelectionMode}
              onShare={() => {
                setSelectedItem({ folder });
                setShowShare(true);
              }}
              onNotification={toggleNotificationSettings}
              query={query}
              onQueryChange={setQuery}
            />
          )}
        </>
      )}
      <Loading
        linear={false}
        spinning={uploading}
        tip={
          uploading && `Uploading ${uploading.completed} of ${uploading.total}`
        }
      >
        <FileDropZone
          onDrop={onFileDrop}
        >
          <Container maxWidth="md" style={{ marginTop: 8 }}>
            {selectionMode ? (
              <FolderSelectionActionsBar
                toggleCheckAll={toggleCheckAll}
                selectedCount={selections.length}
                selections={selections}
                allSelected={notes.length === selections.length}
                toggleSelectionMode={toggleSelectionMode}
                onMoveItems={moveItems}
                onCreateFolder={onCreateFolder}
              />
            ) : null}
            {isRoot && (
              <Box>
                <Typography paragraph variant={"h5"}>
                  Notes
                </Typography>
              </Box>
            )}
            {view === "card" && (
              <FoldersCard
                folder={folder}
                onSelectFolder={onSelectFolder}
                onAddFolder={() => setShowAddModal(true)}
                folders={folders}
                files={notes}
                selectionMode={selectionMode}
                onSelect={onFileSelect}
                selections={selections}
                onFileAction={onFileAction}
                onFolderAction={onFolderAction}
                onFilesDrop={onFileDrop}
                onNotesDrop={onNotesDrop}
              />
            )}
            {view === "list" && (
              <FoldersList
                folder={folder}
                onSelectFolder={onSelectFolder}
                onAddFolder={() => setShowAddModal(true)}
                folders={folders}
                files={notes}
                selectionMode={selectionMode}
                onSelect={onFileSelect}
                selections={selections}
                onFileAction={onFileAction}
                onFolderAction={onFolderAction}
                onFilesDrop={onFileDrop}
                onNotesDrop={onNotesDrop}
              />
            )}
            {view === "map" && <MapView files={notes} />}
          </Container>
        </FileDropZone>
      </Loading>

      {showShare && selectedItem && <ShareModal {...selectedItem} onClose={toggleShareModal} />}
      {showNotification && (
        <NotificationSettings
          user={user}
          folderId={folder.id}
          onClose={toggleNotificationSettings}
        />
      )}
      {showAddModal && (
        <CreateFolder
          title={selectedItem ? "Edit Folder" : "New Folder"}
          onClose={() => {
            setShowAddModal(false);
            setSelectedItem();
          }}
          onSave={onCreateFolder}
          folder={selectedItem?.folder}
        />
      )}
    </Box>
  );
};

export default FolderView;
