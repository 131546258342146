import last from 'lodash/last';
import NoteTypes from "../constants/noteTypes";

const buildNoteContentObject = (type, value, fileType) => {
    switch (type) {
        case NoteTypes.Text: {
            return {
                schema_version: 1,
                text: {
                    html_text: value,
                    schema_version: 1,
                },
            };
        }
        case NoteTypes.Audio: {
            return {
                schema_version: 1,
                audio: {
                    audio: value,
                    schema_version: 1,
                }
            }
        }
        case NoteTypes.Video:
            return  {
                schema_version: 1,
                video: {
                    video: value.replace('.webm', '.mp4'),
                    schema_version: 1,
                }
            }
        case NoteTypes.Photo:
            return {
                schema_version: 1,
                photos: Array.isArray(value) ? value.map(o => ({
                    image: o,
                    original: o,
                    schema_version: 1,
                })) : [{
                    image: value,
                    original: value,
                    schema_version: 1,
                }]
            }
        case NoteTypes.Document:
            return {
                schema_version: 1,
                file_id: value,
                file_type: fileType || last(value.split('.'))
            }
    }
};

export default buildNoteContentObject;