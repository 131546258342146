import React, { Fragment, useState, useCallback, forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/Button';

const defaultOptions = {
  title: 'Are you sure?',
  message: '',
  confirmationText: 'Yes',
  cancellationText: 'Cancel',
  dialogProps: {},
  confirmButtonProps: {},
  onClose: () => {},
  onCancel: () => {},
};

const withConfirm = (WrappedComponent) => {
  const component = forwardRef((props, forwardedRef) => {
    const [onConfirm, setOnConfirm] = useState(null);
    const [options, setOptions] = useState(defaultOptions);
    const {
      title,
      message,
      confirmationText,
      cancellationText,
      dialogProps,
      confirmButtonProps,
      onClose,
      onCancel,
    } = options;
    const handleClose = useCallback(() => {
      onClose();
      setOnConfirm(null);
    }, [onClose]);
    const handleCancel = useCallback(() => {
      onCancel();
      handleClose();
    }, [onCancel, handleClose]);
    const handleConfirm = useCallback(
      (...args) => {
        const resp = onConfirm(...args);
        if (resp && resp.then) {
          return resp.then((resp) => {
            handleClose();
            return resp;
          });
        } else {
          handleClose();
        }
      },
      [onConfirm, handleClose],
    );
    const confirm = useCallback((onConfirm, options = {}) => {
      setOnConfirm(() => onConfirm);
      setOptions({ ...defaultOptions, ...options });
    }, []);
    return (
      <Fragment>
        <WrappedComponent {...props} confirm={confirm} ref={forwardedRef} />
        <Dialog
          fullWidth
          {...dialogProps}
          open={!!onConfirm}
          onClose={handleCancel}
        >
          {title && <DialogTitle>{title}</DialogTitle>}
          {message && (
            <DialogContent>
              <DialogContentText color={"textPrimary"}>{message}</DialogContentText>
            </DialogContent>
          )}
          <DialogActions style={{ padding: 20 }}>
            <Button onClick={handleCancel}>{cancellationText}</Button>
            <Button onClick={handleConfirm} color="primary" {...confirmButtonProps}>
              {confirmationText}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  });
  component.displayName = WrappedComponent.displayName;
  return component;
};

export default withConfirm;
