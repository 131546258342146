import React from 'react';
import styled from 'styled-components';
import { Consumer } from 'context/annotation';
import stc from "string-to-color";

const Container = styled.div`
  align-items: center;
  background-color: ${props => props.background || '#c0392b' };
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25);
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 38px;
  justify-content: center;
  position: absolute;
  text-shadow: 0 1px 3px rgba(0,0,0,0.25);
  text-transform: uppercase;
  transform: translate3d(-50%, -50%, 0);
  width: 38px;
`;

function Point(props) {
  const { geometry, data } = props.annotation;
  const initials = data && data.initials ? data.initials : false;

  if (!geometry) return null;

  return (
    <Consumer>
      {() => {
        return (
          <Container
            background={data && stc(data.name)}
            style={{
              top: `${geometry.y}%`,
              left: `${geometry.x}%`,
            }}
          >
            {initials}
          </Container>
        );
      }}
    </Consumer>
  );
}

export default Point;
