import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/Button";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    borderRadius: 0,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: 14,
  },
}));

const Footer = ({
  isShared,
  disabled,
  onToggleShare,
  onSave,
  onCancel,
  saveLabel,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={isShared}
                onChange={(e) => onToggleShare(e.target.checked)}
                color="primary"
              />
            }
            label={"Public Note"}
            classes={{
              label: classes.label,
            }}
          />
        </Box>
        <Box marginLeft="auto" display="flex">
          <Button onClick={onCancel}>Cancel</Button>{" "}
          <Button
            fullWidth
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            {saveLabel}
          </Button>
        </Box>
      </Container>
    </Paper>
  );
};

export default Footer;
