import React, { useMemo } from 'react';
import ReactPlayer from "react-player/lazy";

const AudioPlayer = ({ audioFile, fileUrl }) => {
    const url = useMemo(() => audioFile ? URL.createObjectURL(audioFile) : fileUrl, []);


    return (
        <div>
            <ReactPlayer
                controls
                className="react-player"
                width="100%"
                height={55}
                url={url}
                config={{
                    file: {
                        forceAudio: true
                    }
                }}
            />
        </div>
    )
};

export default AudioPlayer;