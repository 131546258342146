import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import NewItemMenu from "../NewItemMenu";
import FileDragZone from "components/FileDragZone";
import FolderItem from "./FolderItem";
import FileItem from "./FileItem";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed #ccc",
    height: 130,
    width: 120,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    cursor: "pointer",
    "&: hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const CardView = ({
  folder,
  folders,
  files = [],
  selectionMode,
  onSelect,
  selections,
  onFileAction,
  onFolderAction,
  onAddFolder,
  onFilesDrop,
  onNotesDrop,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <FileDragZone>
        <Grid container>
          <Grid item md={2} sm={3} xs={4}>
            <NewItemMenu folderId={folder?.id} onAddFolder={onAddFolder}>
              <Box className={classes.addBtn}>
                <AddIcon color="primary" fontSize={"large"} />
                <Typography color="primary">New</Typography>
              </Box>
            </NewItemMenu>
          </Grid>
          {folders.map((i) => (
            <Grid item md={2} sm={3} xs={4} key={i.id}>
              <FolderItem
                folder={i}
                selectionMode={selectionMode}
                onSelect={onSelect}
                selected={selections.includes(i)}
                onFolderAction={onFolderAction}
                onFilesDrop={onFilesDrop}
                onNotesDrop={onNotesDrop}
              />
            </Grid>
          ))}

          {files.map((i) => (
            <Grid item md={2} sm={3} xs={4} key={i.id}>
              <FileItem
                file={i}
                selectionMode={selectionMode}
                onSelect={onSelect}
                selected={selections.includes(i)}
                onFileAction={onFileAction}
              />
            </Grid>
          ))}
        </Grid>
      </FileDragZone>
    </Grid>
  );
};

export default CardView;
