import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: 20,
    '& h1': {

    }
  }
}));

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h1" color="textPrimary">404</Typography>
      <Typography variant="body1">The document you're looking for is deleted or no longer shared.</Typography>
    </div>
  )
};
