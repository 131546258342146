import React, { Fragment } from "react";
import styled from "styled-components";
import T from "prop-types";
import fullscreen from "../../assets/fullscreen.svg";
import iconDownload from "../../assets/cloud-download.svg";

const Container = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(0,0,0,.6);
  display: flex;
  align-items: center;
  z-index: 1;
  border-radius: 6px;
  padding: 5px;
  @media all and (max-width: 768px) {
    top: auto;
    bottom: 10px;
    right: 10px;
    position: fixed;
  }

  margin-left: auto;
  .sep {
    width: 1px;
    height: 24px;
    background-color: #7A7A7A;
    margin: 0 4px;
  }
  .icon-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 4px;
    line-height: 0;
    img {
      width: 24px;
      height: 24px;
      color: white;
    }
    &:hover {
      img {
        opacity: .8;
      }
    }
  }
`;

class ActionBar extends React.Component {
  static propTypes = {
    actions: T.array,
    showAllAnnotations: T.bool,
    hasSelected: T.bool,
    onToggleComments: T.func,
    onDeleteComment: T.func,
    onCloseComment: T.func,
    onZoom: T.func,
    onFullScreen: T.func,
  };

  hide = () => {
    this.props.onCloseComment();
  };

  zoomIn = () => {
    this.props.onZoom(1);
  };

  zoomOut = () => {
    this.props.onZoom(-1);
  };

  refresh = () => {
    window.location.reload();
  };

  download = () => {};

  render() {
    const { imageUrl, onFullScreen } = this.props;
    return (
      <Container className="img-actions">
        <button className="icon-btn" onClick={onFullScreen}>
          <img src={fullscreen} />
        </button>
        <span className="sep" />
        <a href={imageUrl} target="_blank" className="icon-btn">
          <img src={iconDownload} />
        </a>
      </Container>
    );
  }
}

export default ActionBar;
