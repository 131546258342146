import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";

const Root = styled.div`
  height: 100%;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .arrow {
    position: relative;
    padding: 15px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color: transparent;
    cursor: pointer;
    
    > span {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      border-color: #fff;
      border-style: solid;
      border-width: 3px 3px 0 0;
      padding: 15px;
      transition: .3s;
      font-size: 0;
      opacity: .4;
    }
    &.left > span {
      transform: translate(-50%,-50%) rotate(
          -135deg
      );
      margin-left: 10px;
    }

    &.right > span {
      transform: translate(-50%,-50%) rotate(
          45deg
      );
      margin-left: -10px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  button {
    cursor: pointer;
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color: #000;
    border-radius: 50%;

    &:disabled {
      background: #aaa;
      cursor: default;
    }
  }
  .page {
    flex: 1;
    text-align: center;
  }
  .loadable-image {
    width: 100%;
  }
`;

const Dots = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  > div {
    margin: 0 5px;
    width: 60px;
    height: 40px;
    cursor: pointer;
    border: 2px solid #aaa;
    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    &.active {
      border: 2px solid #e88731;
    }
  }
`;

export default class Caousel extends React.Component {
  static propTypes = {
    pages: propTypes.array,
    render: propTypes.func,
    onPageChange: propTypes.func,
    renderDot: propTypes.func,
    activeIndex: propTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  onNext = () => {
    const { pages, activeIndex, onPageChange } = this.props;
    const size = pages.length;
    if (size - 1 > activeIndex) {
      onPageChange(activeIndex + 1);
    }
  };

  onPrev = () => {
    const { activeIndex, onPageChange } = this.props;
    if (activeIndex > 0) {
      onPageChange(activeIndex - 1);
    }
  };

  render() {
    const { pages, activeIndex, render, onPageChange, renderDot } = this.props;

    return (
      <Root>
        <Container>
          {renderDot && (
              <button className="arrow left" onClick={this.onPrev}>
                <span />
              </button>
          )}
          <div className="page">
            {pages.map((p, idx) =>
              render({
                index: idx,
                key: idx,
                data: p,
                style: { display: idx === activeIndex ? "flex" : "none" },
              })
            )}
          </div>
          {renderDot && (
              <button className="arrow right" onClick={this.onNext}>
                <span />
              </button>
          )}
        </Container>
        <Dots>
          {pages.map((i, idx) =>
            renderDot ? (
              renderDot({ page: i, index: idx, active: activeIndex === idx, onClick: () => onPageChange(idx) })
            ) : (
              <div
                key={idx}
                className={activeIndex === idx && "active"}
                onClick={() => onPageChange(idx)}
              >
                <img src={i.image} />
              </div>
            )
          )}
        </Dots>
      </Root>
    );
  }
}
