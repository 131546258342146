import React, { useState, useEffect } from "react";
import cx from "classnames";
import { makeStyles, fade } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import firebase, { auth, storage } from "lib/firebase";
import uuid from "lib/uuid";
import last from "lodash/last";
import MediaUploader from "./MediaUploader";
import Loading from "components/Loading";
import useSnack from "hooks/useSnack";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  section: {
    border: "2px dashed",
    borderColor: theme.palette.action.disabledBackground,
    borderRadius: 4,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  thumbBox: {
    width: 70,
    height: 70,
    borderRadius: 6,
    marginRight: theme.spacing(1),
    cursor: "pointer",
    border: "2px solid",
    borderColor: fade(theme.palette.text.disabled, 0.1),
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "&.selected": {
      borderColor: theme.palette.primary.main,
    },
  },
  previewContainer: {
    position: "relative",
  },
  actions: {
    position: "absolute",
    top: 4,
    right: 4,
    borderRadius: 4,
    backgroundColor: "rgba(0,0,0,.7)",
    padding: 4,
  },
  preview: {
    width: "100%",
    height: "100%",
    maxHeight: 400,
    objectFit: "contain",
  },
  addBox: {
    border: "1px solid",
    borderColor: fade(theme.palette.text.disabled, 0.2),
    width: 70,
    height: 70,
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

async function getFileUrls(files, uid) {
  return Promise.all(
    files.map((i) => {
      return storage
        .ref(`user-content/${uid}/image/${i}`)
        .getDownloadURL()
        .then((resp) => {
          return {
            id: i,
            progress: 100,
            preview: resp,
          };
        });
    })
  );
}

const NoteTypePhoto = ({ value, onChange }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [selected, setSelected] = useState();
  const snack = useSnack();

  useEffect(() => {
    if (value) {
      const user = auth.currentUser;
      getFileUrls(value, user.uid).then((resp) =>
        setFiles((prev) => [...prev, ...resp])
      );
    }
  }, []);

  const handleFile = (file) => {
    if (file && file.type.includes('image')) {
      file.id = uuid();
      file.preview = URL.createObjectURL(file);
      setFiles((prev) => [...prev, file]);
      uploadFile(file).catch(e => snack.error('Error uploading file.'));
    } else {
      snack.error('Please select image file');
    }
  };

  const onFileSelect = (e) => {
    const files = e.target.files;
    const file = files[0];
    handleFile(file);
  };

  const onFilesDrop = (files) => {
    const f = files[0];
    handleFile(f);
  };

  const uploadFile = (f) => {
    setSelected(f);
    return new Promise((resolve, reject) => {
      setUploading(true);
      const user = auth.currentUser;
      const ext = last(f.name.split("."));
      const name = f.id + "." + ext;
      const storage = firebase.storage().ref(`user-content/${user.uid}/image`);
      const uploadTask = storage.child(name).put(f);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const pcDone = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          f.progress = pcDone;
        },
        (error) => {
          alert(error.message);
          setUploading(false);
          reject(error);
        },
        () => {
          onChange(value ? [...value, name] : [name]);
          setUploading(false);
          resolve(name);
        }
      );
    });
  };

  const removeFile = () => {
    URL.revokeObjectURL(selected.preview);
    const filteredFiles = files.filter((f) => f !== selected);
    setFiles(filteredFiles);
    onChange(value.filter((name) => !name.startsWith(selected.id)));
    setSelected(filteredFiles[0]);
  };

  return (
    <Card elevation={0} className={classes.root}>
      <Box className={classes.section}>
        {selected ? (
          <Loading
            linear={false}
            spinning={selected.progress !== 100}
            tip={`Uploading... ${selected.progress || 0}%`}
          >
            <Box className={classes.previewContainer}>
              <Box className={classes.actions}>
                <IconButton size="small" color="primary" onClick={removeFile}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <img className={classes.preview} src={selected.preview} />
            </Box>
          </Loading>
        ) : (
          <MediaUploader
            placeholder="Drag your document here"
            onFilesDrop={onFilesDrop}
            onFileSelect={onFileSelect}
            uploading={uploading}
            accept="image/*"
          />
        )}
      </Box>
      <Box display="flex">
        {files.map((f) => (
          <Loading spinning={f.progress !== 100} linear={false} key={f.name}>
            <Box
              className={cx(classes.thumbBox, { selected: f === selected })}
              onClick={() => setSelected(f)}
            >
              <img src={f.preview} />
            </Box>
          </Loading>
        ))}
        {files.length ? (
          <Box className={classes.addBox}>
            <IconButton color="primary" onClick={() => setSelected()}>
              <AddIcon fontSize="large" />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </Card>
  );
};

export default NoteTypePhoto;
