import React, { useContext, useState, useEffect } from "react";
import { Link as NavLink } from "react-router-dom";
import Helmet from "react-helmet/es/Helmet";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import Button from "components/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { auth } from "lib/firebase";
import useSnack from "hooks/useSnack";
import thinkersLogo from "assets/thinkers_logo.svg";
import AppBar from "components/AppBar";
import SessionContext from "../../context/session";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 70,
    height: 70,
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const Settings = ({}) => {
  const classes = useStyles();
  const { user } = useContext(SessionContext);
  const [locationPermission, setLocationPermission] = useState(false);

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((resp) => {
        if (resp.state === "granted") {
          setLocationPermission(true);
        }
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (pos) {
        setLocationPermission(true);
      });
    }
  }, []);

  const onToggleLocation = (e) => {
    const { checked } = e.target;
    if (checked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (pos) {
          setLocationPermission(true);
        });
      }
    }
  };

  return (
    <Box>
      <Helmet title="Settings" />
      <AppBar isRoot />
      <Container maxWidth="md" style={{ marginTop: 24 }}>
        <Typography
          gutterBottom
          style={{ fontWeight: 700 }}
          variant="h5"
        >
          Settings
        </Typography>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginBottom: 16 }}
            >
              <Grid item>
                <Avatar
                  alt={user.name}
                  src={user.image}
                  className={classes.avatar}
                />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Typography style={{ fontWeight: 700 }} variant="h6">
                  {user.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Premium Account
                </Typography>
              </Grid>
              <Grid item style={{ alignSelf: "flex-start" }}>
                <Button color="primary">Edit Account Info</Button>
              </Grid>
            </Grid>
            <Divider />
            <Box
              mt={2}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {/*<Typography style={{ fontWeight: 700 }} variant="subtitle1">*/}
              {/*  Space Used*/}
              {/*</Typography>*/}
              {/*<Typography variant="subtitle1">50%</Typography>*/}
            </Box>
            {/*<Box>*/}
            {/*  <BorderLinearProgress variant="determinate" value={50} />*/}
            {/*</Box>*/}
            {/*<Box mt={0.5} mb={2}>*/}
            {/*  <Typography color="textSecondary" variant="caption">*/}
            {/*    2.3GB • 200 Files*/}
            {/*  </Typography>*/}
            {/*</Box>*/}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography style={{ fontWeight: 500 }} variant="subtitle1">
                Location Awareness
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={locationPermission}
                    onChange={onToggleLocation}
                    color="primary"
                  />
                }
                label={locationPermission ? "On" : "Off"}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Settings;
