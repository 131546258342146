import React, { useState } from "react";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from 'material-ui-confirm';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as TrashIcon } from "assets/icons/delete.svg";
import { ReactComponent as ReplyIcon } from "assets/icons/reply.svg";
import { ReactComponent as SendIcon } from "assets/icons/send.svg";
import { firestore, serverTimestamp } from "lib/firebase";
import Loading from "components/Loading";
import Avatar from "components/Avatar";
import usePromise from "hooks/usePromise";

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: "#E9E9EA",
    borderRadius: 4,
    padding: "4px 8px",
    border: "1px solid",
    borderColor: "transparent",
    transition: "all .2s ease",
    "&.Mui-focused": {
      borderColor: theme.palette.primary.main,
      backgroundColor: "transparent"
    }
  },
  name: {
    marginRight: theme.spacing(2),
    lineHeight: 1
  },
  timestamp: {
    marginRight: "auto",
    lineHeight: 1
  },
  avatarLg: {
    marginRight: theme.spacing(1),
    width: 36,
    height: 36
  },
  avatarSm: {
    marginRight: theme.spacing(1),
    width: 24,
    height: 24,
    fontSize: 12
  },
  parentComment: {
    position: "relative",
    "&::before": {
      position: "absolute",
      content: '""',
      top: 38,
      width: 200,
      bottom: 30,
      left: 10,
      zIndex: 0,
      borderRadius: 10,
      borderLeft: "2px solid #E6E6E6",
      borderTopLeftRadius: 0
    }
  },
  childComment: {
    position: "relative",
    "&::before": {
      top: 0,
      left: -6,
      width: 15,
      content: '""',
      position: "absolute",
      borderBottom: "2px solid #E6E6E6",
      borderBottomLeftRadius: 10,
      borderTopLeftRadius: 0,
      height: 10,
      borderLeft: "2px solid #e6e6e6"
    }
  }
}));

const ChildComment = ({ comment, deleteComment }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      marginBottom={2}
      marginLeft={2}
      className={classes.childComment}
    >
      <Avatar
        name={comment.author_name}
        src={comment.author_image_url}
        className={classes.avatarSm}
      />
      <Box flex={1}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" className={classes.name}>
            {comment.author_name}
          </Typography>
          <Typography
            className={classes.timestamp}
            variant="caption"
            color="textSecondary"
          >
            {dayjs(comment.created?.toDate()).format("MMMM DD, YYYY h:mm A")}
          </Typography>
          {deleteComment && (
              <IconButton
                  style={{ marginRight: 38 }}
                  color="primary"
                  size="small"
                  onClick={() => deleteComment(comment.id)}
              >
                <TrashIcon />
              </IconButton>
          )}
        </Box>
        <Typography variant="body1">{comment.text}</Typography>
      </Box>
    </Box>
  );
};

const Comment = ({ comment, onSelectReply, replying, getCommenterName, addComment, deleteComment }) => {
  const classes = useStyles();
  const [text, setText] = useState("");


  const [saveHandler, saving] = usePromise(() => {
    return getCommenterName().then(name => {
      if (name) {
        setText('');

        return addComment({
          text,
          parentCommentId: comment.id,
          noteUserId: comment.note_user_id,
          authorName: name
        });
      }
    });
  });

  const onChange = e => {
    setText(e.target.value);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && text) {
      saveHandler();
    }
  }

  return (
    <Box flex={1} marginBottom={3}>
      <Box display="flex" marginBottom={2}>
        <Avatar
          name={comment.author_name}
          src={comment.author_image_url}
          className={classes.avatarLg}
        />
        <Box flex={1}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" className={classes.name}>
              {comment.author_name}
            </Typography>
            <Typography
              className={classes.timestamp}
              variant="caption"
              color="textSecondary"
            >
              {dayjs(comment.created?.toDate()).format("MMMM DD, YYYY h:mm A")}
            </Typography>
            {deleteComment && (
                <IconButton
                    style={{ marginRight: 8 }}
                    color="primary"
                    size="small"
                    onClick={() => deleteComment(comment.id)}
                >
                  <TrashIcon />
                </IconButton>
            )}
            {replying ? (
              <Button variant="text" onClick={() => onSelectReply()}>
                Cancel
              </Button>
            ) : (
              <IconButton
                color="primary"
                size="small"
                onClick={() => onSelectReply(comment)}
              >
                <ReplyIcon />
              </IconButton>
            )}
          </Box>
          <Typography variant="body1">{comment.text}</Typography>
        </Box>
      </Box>
      {comment.children.map(c => (
        <ChildComment key={c.id} comment={c} deleteComment={deleteComment} />
      ))}
      {replying && (
        <Box marginLeft={2} display="flex" alignItems="center">
          <Avatar
            name={comment.author_name}
            src={comment.author_image_url}
            className={classes.avatarLg}
          />
          <Input
            fullWidth
            autoFocus
            margin={"none"}
            disableUnderline
            placeholder="Add reply..."
            className={classes.input}
            value={text}
            onChange={onChange}
            onKeyDown={onKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={saveHandler}
                  disabled={!text || saving}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
      )}
    </Box>
  );
};

const Comments = ({ doc, comments, loading, getCommenterName, addComment, deleteComment }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [replyThread, setReplyThread] = useState();
  const [text, setText] = useState("");

  const [saveHandler, saving] = usePromise(() => {
    return getCommenterName().then(
      name => {
        if (!name) return;

        setText('');

        return addComment({
          text,
          parentCommentId: replyThread ? replyThread.id : null,
          noteUserId: doc.user_id,
          authorName: name
        });
      },
      e => {
        console.log(e);
      }
    );
  });

  const onChange = e => {
    setText(e.target.value);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && text) {
      saveHandler();
    }
  }

  const onDelete = (id) => {
    confirm().then(() => deleteComment(id));
  }

  return (
    <Loading spinning={loading} linear={false}>
      {comments.map(i => (
        <Box flex={1} key={i.id} className={classes.parentComment}>
          <Comment
            comment={i}
            replying={replyThread === i}
            onSelectReply={setReplyThread}
            getCommenterName={getCommenterName}
            addComment={addComment}
            deleteComment={deleteComment && onDelete}
          />
        </Box>
      ))}
      {!replyThread && (
        <Box display="flex" alignItems="center">
          <Avatar className={classes.avatarLg}>MM</Avatar>
          <Input
            fullWidth
            value={text}
            onChange={onChange}
            onKeyDown={onKeyDown}
            margin={"none"}
            disableUnderline
            placeholder="Add Comment..."
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={saveHandler}
                  disabled={saving || !text}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
      )}
    </Loading>
  );
};

export default Comments;
