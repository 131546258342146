import React, { useState, useEffect } from "react";

const MediaRecorder = global.MediaRecorder;

function getDevicesOfKind(deviceInfos, kind) {
  return deviceInfos.filter(function (deviceInfo) {
    return deviceInfo.kind === kind;
  });
}

function saveDeviceSettings(data) {
  try {
    localStorage.setItem("device_settings", JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
}

function getDeviceSettings() {
  try {
    const d = localStorage.getItem("device_settings");
    if (d) {
      return JSON.parse(d);
    }
  } catch (e) {
    console.log(e);
  }
}

async function getUserMedia(constraints, setStream, setErr) {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    window.localstream = stream;
    setStream(stream);
  } catch (err) {
    setErr(getRecorderError(err));
  }
}

function getRecorderError(error) {
  let errMessage = error.message;
  switch (error.name) {
    case "InvalidStateError":
      errMessage = "An error occurred while recording. Try again later.";
      break;
    case "NotAllowedError":
      errMessage = "Camera access denied. Please allow access to record video";
    case "SecurityError":
      errMessage = "Recording is not allowed due to security restrictions.";
      break;
    default:
      errMessage = "An error occurred while recording. Try again later.";
      break;
  }

  return errMessage;
}

function getTracks(stream) {
  return stream.getTracks();
}

function removeTrack(stream, track) {
  track.stop();
  stream.removeTrack(track);
}

export function getDeviceSelectionOptions() {
  // before calling enumerateDevices, get media permissions (.getUserMedia)
  // w/o media permissions, browsers do not return device Ids and/or labels.
  return ensureMediaPermissions().then(function () {
    return navigator.mediaDevices
      .enumerateDevices()
      .then(function (deviceInfos) {
        var kinds = ["audioinput", "videoinput"];
        return kinds.reduce(function (deviceSelectionOptions, kind) {
          deviceSelectionOptions[kind] = getDevicesOfKind(deviceInfos, kind);
          return deviceSelectionOptions;
        }, {});
      });
  });
}

function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then(function (devices) {
      return devices.every(function (device) {
        return !(device.deviceId && device.label);
      });
    })
    .then(function (shouldAskForMediaPermissions) {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(function (mediaStream) {
            mediaStream.getTracks().forEach(function (track) {
              track.stop();
              // if (track.kind === 'video') {
              //   if (track.enabled) {
              //     track.enabled = false;
              //   }
              // }
            });
          });
      }
    });
}

let startDate;

export const useVideoRecorder = ({
  handleFinishRecording,
}) => {
  const [data, setData] = useState([]);
  const [canvas, setCanvas] = useState();
  const [recorder, setRecorder] = useState(null);
  const [duration, setDuration] = useState(0);
  const [recording, setRecording] = useState(false);
  const [stream, setStream] = useState({});
  const [devices, setDevices] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const saved = getDeviceSettings();
    if (saved) {
      setDevices(saved);
    }
    if (!MediaRecorder) {
      setError('Unsupported browser');
    }
    return () => {
      // hack to stop stream when component unmounts
      if (window.localstream) {
        window.localstream.getTracks().forEach(track => track.stop());
        delete window.localstream;
      }
    }
  }, []);

  // Get camera permission - only after recording started
  useEffect(() => {
    if (canvas) {
      console.log('get userMedia')
      let constraints = { audio: true, video: true };
      if (devices?.audioinput) {
        constraints.audio = {
          deviceId: devices.audioinput,
        };
      }
      if (devices?.videoinput) {
        constraints.video = {
          deviceId: devices.videoinput,
        };
      }
      getUserMedia(constraints, setStream, setError);
    }
  }, [canvas, devices]);

  // set canvas stream after stream stated
  useEffect(() => {
    if (stream.id && canvas) {
      console.log('set stream to canvas');
      canvas.srcObject = stream;
      canvas.captureStream = canvas.captureStream || canvas.mozCaptureStream;
    }
  }, [stream.id, canvas]);


  // start capture
  useEffect(() => {
    if (recording && canvas && stream.id) {
      if (!MediaRecorder) {
        setError("Unsupported browser");
      } else {
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: "video/webm",
        });
        mediaRecorder.ondataavailable = (e) => {
          setData(prev => [...prev, e.data]);
          const newDuration = startDate ? new Date().getTime() - startDate.getTime() : 0;
          setDuration(newDuration);
        };
        mediaRecorder.onerror = (e) => setError(getRecorderError(e.error));
        startDate = new Date();
        mediaRecorder.start(1000);
        setRecorder(mediaRecorder);
      }
    }
  }, [recording, canvas, stream]);

  const clearRecorder = () => {
    setData([]);
    setRecorder(null);
    setStream({});
    setDuration(0);
  };

  const onSaveDevices = (s) => {
    setDevices(s);
    saveDeviceSettings(s);
  };

  const toggleRecording = () => {
    recording ? stop() : start();
  };

  const start = () => {
    clearRecorder();
    setRecording(true);
  };

  const stop = () => {
    if (recorder) {
      console.log('stop recorder');
      recorder.stop();
    }
    setRecording(false);
    const options = {
      type: 'video/webm',
    };
    const blob = new Blob(data, options);
    handleFinishRecording(blob);
    cleanup();
  };

  const cleanup = () => {
    if (stream.id) {
      stream.getTracks()
          .forEach( track => track.stop() );
    }
  };

  return {
    setCanvas,
    duration,
    recording,
    toggleRecording,
    error,
    cleanup,
    devices,
    setDevices: onSaveDevices,
  };
};
