import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import FileDragZone from "components/FileDragZone";
import FileItem from "./FileItem";
import FolderItem from "./FolderItem";
import NewItemMenu from "../NewItemMenu";

const useStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: theme.spacing(4),
  },
  listItem: {
    "& .MuiListItem-button:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const ListView = ({
  folder,
  folders,
  files = [],
  onAddFolder,
  selectionMode,
  onSelect,
  selections,
  onFolderAction,
  onFilesDrop,
  onNotesDrop,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <FileDragZone>
        <List disablePadding>
          <NewItemMenu folderId={folder?.id} onAddFolder={onAddFolder}>
            <ListItem
              divider
              button
              classes={{
                container: classes.listItem,
              }}
            >
              <ListItemIcon className={classes.listIcon}>
                <AddIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="New"
                primaryTypographyProps={{ color: "primary" }}
              />
              <ListItemSecondaryAction />
            </ListItem>
          </NewItemMenu>
          {folders.map((i) => (
            <FolderItem
              key={i.id}
              folder={i}
              onSelect={onSelect}
              selected={selections.includes(i)}
              selectionMode={selectionMode}
              onFolderAction={onFolderAction}
              onFilesDrop={onFilesDrop}
              onNotesDrop={onNotesDrop}
            />
          ))}
          {files.map((i) => (
            <FileItem
              item={i}
              key={i.id}
              onSelect={onSelect}
              selected={selections.includes(i)}
              selectionMode={selectionMode}
            />
          ))}
        </List>
      </FileDragZone>
    </Card>
  );
};

export default ListView;
