import React, { useState, useEffect } from 'react';
import store from 'store';

export default function useLayout() {
    const [layout, setLayout] = useState(store.get('layout') || 'card');

    const onSetLayout = (val) => {
        setLayout(val);
        store.set('layout', val);
    }

    return [layout, onSetLayout];
}