import React from "react";
import Avatar from "@material-ui/core/Avatar";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import stc from "string-to-color";

export default ({ name, src, ...props }) => {
  const parts = name?.split(" ");
  const initials =
    parts && (parts.length > 1 ? parts[0][0] + parts[1][0] : name.substr(0, 2));

  return (
    <Avatar
      src={src}
      style={{
        backgroundColor: stc(name),
        textTransform: "uppercase",
        fontSize: '90%',
        fontWeight: 'bold'
      }}
      {...props}
    >
      {initials || <PeopleAltIcon fontSize={"small"} />}
    </Avatar>
  );
};
