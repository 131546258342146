import React from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function AnnotationComment({ onClose, onSubmit }) {
  const [comment, setComment] = React.useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = () => {
    onSubmit(comment);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth={"sm"} fullScreen={fullScreen}>
      <DialogTitle onClose={onClose}>Add Comment</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          multiline
          margin="normal"
          label="Your comment"
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
        <Box marginBottom={2} marginTop={1}>
          <Button size="large" disabled={!comment} onClick={handleSubmit} color="primary" variant="contained">
            Save Comment
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
